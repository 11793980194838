
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { highlightMatchesReport, calculateAverageMatch, translations } from '../Reusables/Utilities';

const GrammarReport = () => {
    let navigate = useNavigate();
    const {writeConversationIndex, userWriteAnswers, level, englishTopic } = useUserAnswers(); 
    const conversation = userWriteAnswers[writeConversationIndex];
    const { selectedLanguage } = useLanguage();
  
    useEffect(() => {
        const audio = new Audio('/Audio/report.wav');
        audio.play().catch((error) => console.error('Audio playback failed:', error));
    }, []);

    return (
        <div className="page-container">
            <div className="top-row">
                <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>        
                <h3 dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>{translations[selectedLanguage].YouScore} {calculateAverageMatch(conversation).toFixed(0)}%</h3>
                <BurgerMenuComponent />
            </div> 
            <div className="specific-report" >            
            <ul>
                {conversation.pairs.map((pair, pairIndex) => (
                    <div key={pairIndex} className="result-box">   
                        <div className="line-1">                    
                            <span className="label">{translations[selectedLanguage].Detected}:</span>
                            <span className="content">{highlightMatchesReport(pair.userAnswer, pair.matchSuggestResponse)}</span>
                        </div>
                        <div className="line-2">  
                            <span className="label">{translations[selectedLanguage].Expected}:</span>
                            <span className="content">{pair.matchSuggestResponse}</span>                   
                        </div>
                    </div>  
                ))}
            </ul> 
            <div style={{marginTop:'30px', textAlign:'right'}}>
            <button data-language={selectedLanguage} data-level={level} data-topicwrite={englishTopic} className="grammar-report-next-button" onClick={() => navigate('/completion-page')}>
                <b>{translations[selectedLanguage].next}</b>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
            </button>  
            <br/><br/><br/><br/>
            </div> 
            </div>
            <BottomMenuComponent steps={[]}/>                          
        </div>
    );   
};

export default GrammarReport;