
import React, { useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecordUser } from '../Reusables/RecordUser';
import './Profile.css'; 

const Profile = () => {
    let navigate = useNavigate();
    const { stopAudioRecordingRedbutton} = useRecordUser();

    const buttonGroups = [
      ['Email', 'Preferences', 'Referral Code', 'Achievement'],  
      ['Subscription', 'Billing', 'My Credit'],
      ['App features', 'FAQ', 'News'],
      ['Privacy', 'Terms of use'],
      ['About AIducare', 'Sustainability Report', 'Contact AIducare'],
      ['Sign out']
    ];

    useEffect(() => {
      return () => {
        stopAudioRecordingRedbutton(); 
      };
    }, []);
   
    return (
      <div className="page-container">
        <div className="top-row">
          <button onClick={() => navigate(-1)} className="back-button">Back</button>
        </div>
        {buttonGroups.map((group, index) => (
          <div className="button-group" key={index}>
            {group.map((button, idx) => (
              <div className="button-item" key={idx}>
                <button className={"inactive"}>
                  {button}
                  {<span className="lock-icon">&#128274;</span>}  
                </button>
                {idx < group.length - 1 && <hr />} {/* Add a line between buttons, but not after the last button */}
              </div>
            ))}
          </div>
        ))}        
      </div>
  );
};

export default Profile;