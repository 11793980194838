import React, { useState, useEffect, useRef, useContext, useCallback} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { useFavorites } from '../StudyMenu/FavoriteVocab';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { useClickWord } from '../Reusables/ClickWord';
import { frToAudioLesson, getNote, playWordAudio, showPartOfSpeech, highlightMatchesUpdatePoint, isObjectEmpty, getClassName, determineTopicNameFromVerbName, determineIpaPopup, frToAudio, translations, highlightMatchesWord } from '../Reusables/Utilities';
import { useRecordUser } from '../Reusables/RecordUser';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Learn.css';

const SpeakingSentences = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const { sentencesForLesson, fetchLessonSentences, sentencesForStudy, sentencesForStudyForSpeak, fetchConversations} = useContext(DataContext);
  const { lesson, lessonEn, studyIndex, updateStudyIndex, topic, level, englishTopic, points, updatePoints} = useUserAnswers();
  const {  index } = location.state || { index: studyIndex };  
  const { favorites, toggleFavorite } = useFavorites();
  const {isPlaying, isPlayingSlower, playPause, playPauseIPA, stopAudio, play, slowerPause, currentAudioFile} = usePlayAudio(); 
  const {selectedPhrase , setSelectedPhrase, selectedVocab, setSelectedVocab, handleWordClick} = useClickWord();
  const {setTranscription, transcription, recording, startRecording, stopRecording, stopAudioRecordingRedbutton, processingSpeech} = useRecordUser();  
  const [formattedSentence, setFormattedSentence] = useState('');  
  const { selectedLanguage } = useLanguage();
  const speakWord = useRef(false);
  const speakSentence = useRef(false);

  useEffect(() => {
    return () => {      
      stopAudioRecordingRedbutton();    // only run when come from other pages, not run if go to another sentence in this same page
      setFormattedSentence('');
      setSelectedPhrase({});
      setSelectedVocab({});
    };
  }, []);
  
  useEffect(() => { // when search a topic from speaking-sentences page and it remains in this page
    if (topic !== '' && sentencesForStudy.length === 0) {
      fetchConversations(selectedLanguage, topic);
      updateStudyIndex(0); 
      stopAudio();
      stopRecording();
      if (processingSpeech.current) {
        processingSpeech.current = false;
      }
    } else if (lesson !== '' && sentencesForLesson.length === 0){
      fetchLessonSentences(level, selectedLanguage, lesson);
      updateStudyIndex(0);  
      stopAudio();
      stopRecording();     
      if (processingSpeech.current) {
        processingSpeech.current = false;
      }
    }
  }, [topic, lesson]); 

  useEffect(() => {   // come from summary-page
    if(index !== studyIndex){
      updateStudyIndex(index);
    }
  }, [index]);
  
  const goBack = () => {    
    setFormattedSentence('');
    setSelectedPhrase({});
    setSelectedVocab({});
    
    if (studyIndex === 0 && topic !== '') {      
      navigate('/conversation-instruction');      
    } else if (studyIndex === 0 && lesson !== '') {
      navigate('/lesson-instruction');  
    } else {      
      updateStudyIndex(studyIndex - 1);          // remains in speaking-sentences page    
      stopAudio();
      stopRecording();  
      if (processingSpeech.current) {
        processingSpeech.current = false;
      }
    }
  };

  const goToNext = () => {    
    setFormattedSentence('');
    setSelectedPhrase({});
    setSelectedVocab({});
    if(topic !== ''){
      if ( studyIndex < sentencesForStudy.length - 1) {    
        updateStudyIndex(studyIndex + 1);          // remains in speaking-sentences page
        stopAudio();
        stopRecording();  
        if (processingSpeech.current) {
          processingSpeech.current = false;
        }
      } else {    
        navigate('/summary-page', { state: { fromLastSpeaking : true } });
      }
    } else if (lesson !== ''){
      if ( studyIndex < sentencesForLesson.length - 1) {    
        updateStudyIndex(studyIndex + 1);          // remains in speaking-sentences page
        stopAudio();
        stopRecording();
        if (processingSpeech.current) {
          processingSpeech.current = false;
        }
      } else {    
        navigate('/summary-page', { state: { fromLastSpeaking : true } });
      }
    }    
  };

  // only speakingsentences have this feature because users can stay in the same page. Else, if users use arrows while searching for a topic, it will be a bug.
  const goNextInSpeaking = useCallback(() => {
    if((topic !== '' && studyIndex < sentencesForStudy.length - 1) || (lesson !== '' && studyIndex < sentencesForLesson.length - 1)){          
      setFormattedSentence('');
      setSelectedPhrase({});
      setSelectedVocab({});
      updateStudyIndex(studyIndex + 1);          // goNext but remains in speaking-sentences page
      stopAudio();
      stopRecording();  
      if (processingSpeech.current) {
        processingSpeech.current = false;
      }           
    }   
  });
  const goBackInSpeaking = useCallback(() => {
    if (studyIndex > 0) {      
          setFormattedSentence('');
          setSelectedPhrase({});
          setSelectedVocab({});
          updateStudyIndex(studyIndex - 1);          // goBack but remains in speaking-sentences page    
          stopAudio();
          stopRecording();  
          if (processingSpeech.current) {
            processingSpeech.current = false;
          }
        }
  });

  useEffect(() => {   
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {        
        goNextInSpeaking();
      }
      if (event.key === "ArrowLeft") {    
        goBackInSpeaking();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goNextInSpeaking, goBackInSpeaking]); // Add functions as a dependency to avoid stale closures


  /********** TRANSLATION **********/ 
  const [showTranslation, setShowTranslation] = useState(true);
  const translate = () => {    
    setShowTranslation(!showTranslation);
 }  

  // speaking sentence
 const increasePoints = () => {
    updatePoints(1); 
  };
   
  /********** HIGHLIGHT SENTENCE **********/
  useEffect(() => {     
    // if (transcription !== previousTranscriptionRef.current) {
    if (transcription !== ''){ 
      //play audio 
      const audio = new Audio('/Audio/speech-detection.wav');
      audio.play().catch((error) => console.error('Audio playback failed:', error));

      console.log('detected sentence = ' + transcription); 
      if (speakWord.current) {
        // Dynamically set color for the .selected class
        const styleSheet = document.styleSheets[0];
        const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.selectorText === '.selected');
        if (highlightMatchesWord(transcription.replace(/'/g, "\'"), selectedPhrase.fr) ){
          updatePoints(1);
          if (ruleIndex !== -1) {
            // Modify existing rule if .selected already exists
            styleSheet.cssRules[ruleIndex].style.color = '#00C667'; 
          } else {
            // Otherwise, insert a new rule
            styleSheet.insertRule('.selected { color: #00C667; }', styleSheet.cssRules.length);
          }
        } else {
          if (ruleIndex !== -1) {
            styleSheet.cssRules[ruleIndex].style.color = '#F40000'; 
          } else {
            styleSheet.insertRule('.selected { color: #F40000; }', styleSheet.cssRules.length);
          }
        }
        speakWord.current = false;
      } else if (speakSentence.current) {
        const { newFormattedSentence, allCorrect } = highlightMatchesUpdatePoint(transcription.replace(/'/g, "\'"), originalSentence, increasePoints); // replace ' by \'
        setFormattedSentence(newFormattedSentence);
        speakSentence.current = false;
        if (allCorrect) {
          const timer = setTimeout(() => {
            goToNext();
          }, 2000);
          return () => clearTimeout(timer);
        }
      }   
      setTranscription('');
    }     
  }, [transcription]); 

  /********** POPUP INSTRUCTION **********/  
  const audioRef = useRef(null);
  const slowRef = useRef(null);
  const translationRef = useRef(null);
  const microphoneRef = useRef(null);
  const wordRef = useRef(null);

  let steps = [];
  if (lesson !== '' || (topic!== '' && sentencesForStudyForSpeak[studyIndex])) {
    steps = [
      { ref: audioRef, label: translations[selectedLanguage].Audio , text: translations[selectedLanguage].Listentothissentence },
      { ref: slowRef, label: translations[selectedLanguage].SlowLabel, text: translations[selectedLanguage].Adjustaudiospeed },
      { ref: translationRef, label: translations[selectedLanguage].TranslationLabel, text: translations[selectedLanguage].Seetranslation },    
      { ref: microphoneRef, label: translations[selectedLanguage].MicrophoneLabel, text: translations[selectedLanguage].Readthissentence },  
      { ref: wordRef, label: translations[selectedLanguage].WordLabel, text: translations[selectedLanguage].Clickonword },          
    ];
  } else {
    steps = [
      { ref: audioRef, label: translations[selectedLanguage].Audio , text: translations[selectedLanguage].Listentothissentence },
      { ref: slowRef, label: translations[selectedLanguage].SlowLabel, text: translations[selectedLanguage].Adjustaudiospeed },
      { ref: translationRef, label: translations[selectedLanguage].TranslationLabel, text: translations[selectedLanguage].Seetranslation },  
      { ref: wordRef, label: translations[selectedLanguage].WordLabel, text: translations[selectedLanguage].Clickonword },  
    ];
  }
  
  let originalSentence = '';
  
  if(lesson !== '' ){
    originalSentence = sentencesForLesson?.[studyIndex]?.fr;
  }else {
    originalSentence = sentencesForStudy?.[studyIndex]?.fr;
  }

  /********** link to grammar or ipa lessons **********/ 
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isIPAClick, setIsIPAClick] = useState(false);
  const [ipaContent, setipaContent]  = useState('');
  const { grammarContent, setGrammarContent, fetchGrammarContent } = useContext(DataContext);
  
  // Function to fetch content and handle opening the popup
  const handleClickVerbName = () => {
    const topicName = determineTopicNameFromVerbName(selectedVocab.tense_type_fr, selectedVocab.tense_name_fr);          
    handleClick(topicName);
  }

  const handleClick = async (topicName) => {
    try {    
      setCurrentTopicName(topicName);  
      await fetchGrammarContent(topicName, selectedLanguage);  // Fetch the content from the server
      setIsPopupOpen(true); // Open the popup
    } catch (error) {
      console.error('Error fetching topic content:', error);
    }
  };
  
  
  const handleIPAClick = async (ipa) => {
    try {    
      let content = determineIpaPopup(ipa);
      setipaContent(content);
      setIsIPAClick(true);
      setIsPopupOpen(true); // Open the popup
    } catch (error) {
      console.error('Error fetching topic content:', error);
    }
  };

  // Function to close the popup
  const handleClose = () => {
    setIsPopupOpen(false);
    setGrammarContent('');
    setipaContent('');
    setIsIPAClick(false);
  };

  const handleRecordingSentence = () => {
    if (recording){
      stopRecording();
    } else if (!processingSpeech.current){
      speakSentence.current = true;
      startRecording(selectedLanguage);
    } 
  }
  // const handleRecordingWord = () => {
  //   if (recording){
  //     stopRecording();
  //   } else if (!processingSpeech.current){
  //     speakWord.current = true;
  //     startRecording(selectedLanguage);
  //   } 
  // }

  const [topicHistory, setTopicHistory] = useState([]);
  const [currentTopicName, setCurrentTopicName] = useState('');

  // Function to go back to the previous topic
  const backButtonPopupClick = () => {
    if (topicHistory.length > 0) {
      const previousTopic = topicHistory[topicHistory.length - 1];
      setTopicHistory(topicHistory.slice(0, -1)); // Remove the last topic from the stack
      setCurrentTopicName(previousTopic);
      fetchGrammarContent(previousTopic, selectedLanguage); // Fetch the previous topic's content
    } else {
      setIsPopupOpen(false);
      setGrammarContent('');
      setipaContent('');
      setIsIPAClick(false);
    }
  };

  // useEffect to handle adding event listeners after content is loaded
  useEffect(() => {
    if (isPopupOpen) {
      const links = document.querySelectorAll('.link');
      const handleClickWrapper = (event) => {
        event.preventDefault(); // Prevent default link behavior
        const newTopicName = event.target.getAttribute('data-topic');
        setTopicHistory(prevHistory => [...prevHistory, currentTopicName]);
        setCurrentTopicName(newTopicName);
        fetchGrammarContent(newTopicName, selectedLanguage); // Fetch the new content
      };

      links.forEach(link => {
        link.addEventListener('click', handleClickWrapper);
      });

      // Clean up event listeners on component unmount
      return () => {
        links.forEach(link => {
          link.removeEventListener('click', handleClickWrapper);
        });
      };
    }
  }, [isPopupOpen, grammarContent]);

  useEffect(() => {
    const buttons = document.querySelectorAll('.listen-button');
    
    const handlePlayPause = (event) => {
      const audio = event.currentTarget.getAttribute('data-audio');
      playPauseIPA(audio);      
    };

    buttons.forEach(button => {
      button.addEventListener('click', handlePlayPause);
    });
  
    // Cleanup: Remove event listeners when component unmounts or re-renders
    return () => {
      buttons.forEach(button => {
        button.removeEventListener('click', handlePlayPause);
      });
    };
  }, [ipaContent]);

  /********** to close vocabSelect **********/    
  const closeVocabBox = () => {
      setFormattedSentence('');
      setSelectedVocab({});
      setSelectedPhrase({});    
  };
  const vocabRef = useRef(null);
  const handleClickOutside = (event) => { 
    if (vocabRef.current && !vocabRef.current.contains(event.target)) {
      //reset words color
      const styleSheet = document.styleSheets[0];
      const ruleIndex = Array.from(styleSheet.cssRules).findIndex(rule => rule.selectorText === '.selected');      
      if (ruleIndex !== -1) {
        // Modify existing rule if .selected already exists
        styleSheet.cssRules[ruleIndex].style.color = '#062E5F'; // Replace with desired color
      } else {
        // Otherwise, insert a new rule
        styleSheet.insertRule('.selected { color: #062E5F; }', styleSheet.cssRules.length);
      }
    }
  }; 
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  /********** INTERFACE **********/
  return (
    <div className="page-container">
    {(topic === '' && lesson === '') ? (
      <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].ChooseALesson}</button>
    ) : (      
      (topic !== '' && sentencesForStudy.length > 0) ? 
      <div>
      <div className="top-row">
        <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{sentencesForStudyForSpeak[studyIndex] ? (translations[selectedLanguage].studySpeak) : (translations[selectedLanguage].studyListen)}</h3>        
        <BurgerMenuComponent />  
      </div>  
      {/* {sentencesForStudyForSpeak[studyIndex] ?  */}
        <div style={{textAlign:'right', marginRight:'20px', marginTop: '5px'}}>
        <span className="point-bubble" >
          {translations[selectedLanguage].PointsColon}{points}          
        </span>
        </div>
      
      <div className="study-body">         
          {formattedSentence  && formattedSentence !== '' 
          ? 
           <div ref={wordRef} className="sentence-with-controls-2"
              onClick={() => setFormattedSentence('')} // Switch back to originalSentence on click
              style={{ cursor: 'pointer' }} // Indicate clickable behavior
           ><span><b>{formattedSentence}</b></span></div>   
          :  
          (   
            <div ref={wordRef} className="sentence-with-controls-1">      
           {originalSentence && originalSentence.split(' ').map((word, index) =>  {
            let parsedVocab = {};

            try {
                parsedVocab = JSON.parse(sentencesForStudy[studyIndex]?.vocab || '{}');
            } catch (error) {
                console.error("Invalid JSON in vocab:", error); // Log error for debugging
            }

            return (
              <span key={index}>
              <span             
              className={getClassName(parsedVocab, selectedPhrase?.fr || '', word)}
              onClick={() => handleWordClick(parsedVocab, word, originalSentence, index, selectedLanguage)}   
              >
                <b>{word}</b>
              </span>
              &nbsp;</span>);
            })  }   </div>       
            )}             
          <div> 
            <button ref={audioRef} className="speaker-button" onClick={() => playPause(frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic))}>
              <img src={isPlaying && currentAudioFile === frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic) ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying && currentAudioFile === frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
            </button>
            <button ref={slowRef} className="slowdown-button" onClick={() => slowerPause(frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic))}>
              <img src={isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[studyIndex]?.fr, level, englishTopic) ? "/Icons/pause.svg" : "/Icons/turtle.svg"} alt={isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} /> 
            </button>
            <button ref={translationRef} className={`translation-button ${showTranslation ? 'highlighted' : ''}`} onClick={() => translate()}>
              <img
                src={showTranslation ? "/Icons/translation-highlighted.svg" : "/Icons/translation.svg"}
                alt={translations[selectedLanguage].Translation}
              />
            </button>
          </div>      
          
        {isPlayingSlower && currentAudioFile === frToAudio(sentencesForStudy[studyIndex].fr, level, englishTopic) &&
          <div className="translation-area" style={{maxWidth:'500px'}}> <PlaybackRateControl /></div>  }
        {sentencesForStudy[studyIndex]?.translation.trim() !== "" && showTranslation && (
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="translation-area">
            <b>{sentencesForStudy[studyIndex]?.translation || ''}</b>
          </div>
        )}        

        {(selectedVocab && !isObjectEmpty(selectedVocab)) ? (                         
            <div key={index} className="vocab-item" ref={vocabRef}>         
              <div style={{textAlign:'right'}} >
                <button className="x-button-vocab-box" onClick={() => closeVocabBox()}><b>X</b></button>
              </div>  
              {/* {recording ? (
                <button  className="recording-animation-small" onClick={() => handleRecordingWord()}>
                  <img src="/Icons/stop-practice.svg" alt={translations[selectedLanguage].stop} />
                </button>
              )
              :
                <div><button className="microphone-button-small" onClick={() => handleRecordingWord()}>
                <img src="/Icons/microphone alone.svg" alt={translations[selectedLanguage].Microphone} />
                </button></div>
              }  */}
              {/* other forms or numbers or names */}
              {selectedPhrase && selectedPhrase.fr !== '' && selectedPhrase.fr.toLowerCase().trim() !== selectedVocab.fr.toLowerCase().trim() &&
                <span className="french-word">{selectedPhrase.fr}&nbsp;&nbsp;
                <span className="ipa" onClick={() => handleIPAClick(selectedPhrase.ipa)} style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  {selectedPhrase.ipa}
                </span>
                <button className="audio-button" onClick={() => playWordAudio(selectedPhrase.fr, play, englishTopic)}> 
                  <img src="Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button><br/></span>
              }

              {selectedVocab.fr && selectedVocab.fr !== 'Unknown' && selectedVocab.fr !== 'unknown' && <div>  {/* number or name */}
                <span className="french-word">{selectedVocab.fr}</span>
                <span className="ipa" onClick={() => handleIPAClick(selectedVocab.ipa)} style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  {selectedVocab.ipa}
                </span>
                <button className="audio-button" onClick={() => playWordAudio(selectedVocab.fr, play, englishTopic)}>
                  <img src="/Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button>
                <button className="favorite-button" onClick={() => toggleFavorite({ fr: selectedVocab.fr, part_of_speech: selectedVocab.part_of_speech })}>
                  {favorites.has(selectedVocab.fr) ? <img src="/Icons/heartfilled.svg" alt={translations[selectedLanguage].Liked} /> : <img src="/Icons/heart.svg" alt={translations[selectedLanguage].Unliked}/>}
                </button>
                <br/>
              </div>}     
<div  dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>
              {selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 3 ? (
                <span onClick={() =>handleClick('Les verbes pronominaux')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  [{showPartOfSpeech(3, selectedLanguage)}]<br/>
                </span>
              ) : (
                selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 16 ? (
                  <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} onClick={() =>handleClick('mon / ton / son')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                    [{showPartOfSpeech(16, selectedLanguage)}]<br/>
                  </span>
                ) : (
                  selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id !== 'Unknown' && selectedVocab.part_of_speech_id !== 'unknown' && selectedVocab.part_of_speech_id !== 17 && <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>[{showPartOfSpeech(selectedVocab.part_of_speech_id, selectedLanguage)}]<br/></span>         
                )
              )}  
              {selectedVocab.contraction && selectedVocab.contraction.trim() !== '' && (
                selectedVocab.contraction.includes('<br/>') ? (
                  <span>
                    {translations[selectedLanguage].Contraction}:&nbsp; 
                    {selectedVocab.contraction.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  <br/></span>
                ) : (
                  <span>{translations[selectedLanguage].Contraction}: {selectedVocab.contraction}<br/></span>
                )                
              )}
              {selectedVocab.masculine && selectedVocab.masculine.trim() !== '' && (
                <span>{translations[selectedLanguage].Masculine}: {selectedVocab.masculine}<br/></span>
              )}
              {selectedVocab.feminine && selectedVocab.feminine.trim() !== '' && (
                <span>{translations[selectedLanguage].Feminine}: {selectedVocab.feminine}<br/></span>
              )}
              {selectedVocab.plural && selectedVocab.plural.trim() !== '' && (
                <span>{translations[selectedLanguage].Plural}: {selectedVocab.plural}<br/></span>
              )}  
              {selectedVocab.grammar_topic && selectedVocab.grammar_topic.trim() !== '' && (
                <span>
                  {translations[selectedLanguage].GrammarTopic} 
                  {/* {['y / en', 'Les objets', 'Le passif', 'Le gérondif', 'me / te / le', 'Qui'].includes(selectedVocab.grammar_topic) ? ( */}
                  <span
                    style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}
                    onClick={() => handleClick(selectedVocab.grammar_topic)}
                  >
                    {selectedVocab.grammar_topic}
                  </span> <br/>
                </span>
              )}      
              {selectedVocab.note_en && selectedVocab.note_en.trim() !== '' && (<span>{translations[selectedLanguage].Note}{getNote(selectedVocab, selectedLanguage)}<br/></span>)}
              <span style={{marginTop:'10px', marginBottom:'10px'}} className="french-word">{selectedVocab.translation}</span>
</div>
              {(selectedVocab.part_of_speech_id === 2 || selectedVocab.part_of_speech_id === 3) && selectedVocab.tense_name_fr && (
                <div className="conjugation">
                  {!isObjectEmpty(selectedVocab.tense_type_fr) && selectedVocab.tense_type_fr !== '' && selectedVocab.tense_type_fr !== 'Indicatif' ? (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_type_fr}{' - '}{selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  ) : (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  )}
                  <br/>
                  {selectedVocab.conjugation && selectedVocab.conjugation !== '' &&                
                    <span>{selectedVocab.conjugation.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>}
                  
                </div>
              )}

              {isPopupOpen && (
                <div className="popup-link">
                  <div className="popup-link-content">
                  <button onClick={() => backButtonPopupClick()} className="back-button-popup"> <img src="/Icons/arrow-left-popup.svg" alt={translations[selectedLanguage].back} />  </button>
                  <button className="x-button" onClick={() => handleClose()}><b>X</b></button>
                  {isIPAClick ? 
                  (<div><br/><span dangerouslySetInnerHTML={{ __html: ipaContent }} /> </div>)
                  :
                  (<div><h4>{currentTopicName}</h4> 
                  <br/>
                  <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: grammarContent }} /> </div>)
                  }
                  </div>
                </div>
              )}
            </div>
        
        ) :       
        (
          sentencesForStudyForSpeak[studyIndex] && (
            <div className="microphone" onClick={() => handleRecordingSentence()}>
              {recording ?         
                <button  className="recording-animation">
                  {selectedLanguage === 'en' ? <img src="/Icons/stopEN.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'es' ? <img src="/Icons/stopES.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'pt_br' ? <img src="/Icons/stopPTBR.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'ar' ? <img src="/Icons/stopAR.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'fa' ? <img src="/Icons/stopFA.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'zh' ? <img src="/Icons/stopZH.svg" alt={translations[selectedLanguage].Stop} /> 
                  : selectedLanguage === 'vi' ? <img src="/Icons/stopVI.svg" alt={translations[selectedLanguage].Stop} /> 
                  : <img src="/Icons/stop.svg" alt={translations[selectedLanguage].Stop} /> }
                </button>          
              :
              <button ref={microphoneRef} className="microphone-button">
                <img src="/Icons/microphone.svg" alt={translations[selectedLanguage].Microphone} />
              </button>}
            </div>
          )          
        )        
        }  
        <div style={{marginTop:'0px', textAlign:'right'}}>
          <button className="next-button" style={{marginTop:'10px'}} onClick={() => goToNext()}>
            <b>{translations[selectedLanguage].next}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
          </button>  
          <br/><br/><br/><br/>
        </div>
                        
      </div>  
      <BottomMenuComponent steps={steps}/>
      </div>      
       :         
      ( lesson !== '' && sentencesForLesson.length > 0 && <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>          
          <h3>{lesson}</h3>  
          <BurgerMenuComponent />  
        </div>   
        <div style={{textAlign:'right', marginRight:'20px', marginTop: '5px'}} >
          <span className="point-bubble">
          {translations[selectedLanguage].PointsColon}{points}
        </span>
        </div>
        <div className="study-body"> 
          {formattedSentence && formattedSentence !== '' 
          ? 
          <div ref={wordRef} className="sentence-with-controls-2"
              onClick={() => setFormattedSentence('')} // Switch back to originalSentence on click
              style={{ cursor: 'pointer' }} // Indicate clickable behavior
           ><span><b>{formattedSentence}</b></span></div>          
          :  
          (   
            <div ref={wordRef} className="sentence-with-controls-1">          
            {originalSentence && originalSentence.split(' ').map((word, index) =>  {
            let parsedVocab = {};

            try {
                parsedVocab = JSON.parse(sentencesForLesson[studyIndex]?.vocab || '{}');
            } catch (error) {
                console.error("Invalid JSON in vocab:", error); // Log error for debugging
            }

            return (
              <span key={index}>
              <span             
              className={getClassName(parsedVocab, selectedPhrase?.fr || '', word)}
              onClick={() => handleWordClick(parsedVocab, word, originalSentence, index,selectedLanguage)}   
              >
                <b>{word}</b>
              </span>
              &nbsp;</span>);
            })  }   </div>       
            )} 
          <div> 
            <button ref={audioRef} className="speaker-button" onClick={() => playPause(frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level))}>
              <img src={isPlaying && currentAudioFile === frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level) ? "Icons/pause.svg" : "Icons/listen.svg"} alt={isPlaying && currentAudioFile === frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
            </button>
            <button ref={slowRef} className="slowdown-button" onClick={() => slowerPause(frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level))}>
              <img src={isPlayingSlower && currentAudioFile === frToAudioLesson(sentencesForLesson[studyIndex]?.fr, lessonEn, level) ? "/Icons/pause.svg" : "/Icons/turtle.svg"} alt={isPlayingSlower && currentAudioFile === frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} /> 
            </button>
            <button ref={translationRef} className={`translation-button ${showTranslation ? 'highlighted' : ''}`} onClick={() => translate()}>
              <img
                src={showTranslation ? "/Icons/translation-highlighted.svg" : "/Icons/translation.svg"}
                alt={translations[selectedLanguage].Translation}
              />
            </button>
          </div>      
          
        {isPlayingSlower && currentAudioFile === frToAudioLesson(sentencesForLesson[studyIndex].fr, lessonEn, level) &&
          <div className="translation-area" style={{maxWidth:'500px'}}> <PlaybackRateControl /></div>  }
        {sentencesForLesson[studyIndex]?.fr.trim() !== "" && showTranslation && 
            <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="translation-area">
              {/* <b>{getTranslatedSentence(sentencesForLesson[studyIndex] || {}, selectedLanguage)}</b> */}
              <b>{sentencesForLesson[studyIndex]?.translation}</b>
            </div>
        }        

        {(selectedVocab && !isObjectEmpty(selectedVocab)) ? (                         
            <div key={index} className="vocab-item" ref={vocabRef}>
              <div style={{textAlign:'right'}} >
                <button className="x-button-vocab-box" onClick={() => closeVocabBox()}><b>X</b></button>
              </div>  
              {/* {recording ? (
                <button  className="recording-animation-small" onClick={() => handleRecordingWord()}>
                  <img src="/Icons/stop-practice.svg" alt={translations[selectedLanguage].stop} />
                </button>
              )
              :
                <div><button className="microphone-button-small" onClick={() => handleRecordingWord()}>
                <img src="/Icons/microphone alone.svg" alt={translations[selectedLanguage].Microphone} />
                </button></div>
              } */}
              {/* other forms or numbers or names */}
              {selectedPhrase && selectedPhrase.fr !== '' && selectedPhrase.fr.toLowerCase().trim() !== selectedVocab.fr.toLowerCase().trim() &&
                <span className="french-word">{selectedPhrase.fr}&nbsp;&nbsp;
                <span className="ipa" onClick={() => handleIPAClick(selectedPhrase.ipa)} style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  {selectedPhrase.ipa}
                </span>
                <button className="audio-button" onClick={() => playWordAudio(selectedPhrase.fr, play, englishTopic)}> 
                  <img src="Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button><br/></span>
              }

              {selectedVocab.fr && selectedVocab.fr !== 'Unknown' && selectedVocab.fr !== 'unknown' && <div>  {/* number or name */}
                <span className="french-word">{selectedVocab.fr}</span>
                <span className="ipa" onClick={() => handleIPAClick(selectedVocab.ipa)} style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  {selectedVocab.ipa}
                </span>
                <button className="audio-button" onClick={() => playWordAudio(selectedVocab.fr, play, englishTopic)}>
                  <img src="/Icons/listenDictionary.svg" alt={translations[selectedLanguage].Listen} />
                </button>
                <button className="favorite-button" onClick={() => toggleFavorite({ fr: selectedVocab.fr, part_of_speech: selectedVocab.part_of_speech })}>
                  {favorites.has(selectedVocab.fr) ? <img src="/Icons/heartfilled.svg" alt={translations[selectedLanguage].Liked} /> : <img src="/Icons/heart.svg" alt={translations[selectedLanguage].Unliked}/>}
                </button>
                <br/>
              </div>}     
  <div  dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>
              {selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 3 ? (
                <span onClick={() =>handleClick('Les verbes pronominaux')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                  [{showPartOfSpeech(3, selectedLanguage)}]<br/>
                </span>
              ) : (
                selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id === 16 ? (
                  <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} onClick={() =>handleClick('mon / ton / son')} style={{ fontWeight:'600', cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                    [{showPartOfSpeech(16, selectedLanguage)}]<br/>
                  </span>
                ) : (
                  selectedVocab.part_of_speech_id && selectedVocab.part_of_speech_id !== 'Unknown' && selectedVocab.part_of_speech_id !== 'unknown' && selectedVocab.part_of_speech_id !== 17 && <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'}>[{showPartOfSpeech(selectedVocab.part_of_speech_id, selectedLanguage)}]<br/></span>         
                )
              )}  
              {selectedVocab.contraction && selectedVocab.contraction.trim() !== '' && (
                selectedVocab.contraction.includes('<br/>') ? (
                  <span>
                    {translations[selectedLanguage].Contraction}:&nbsp; 
                    {selectedVocab.contraction.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  <br/></span>
                ) : (
                  <span>{translations[selectedLanguage].Contraction}: {selectedVocab.contraction}<br/></span>
                )                
              )}
              {selectedVocab.masculine && selectedVocab.masculine.trim() !== '' && (
                <span>{translations[selectedLanguage].Masculine}: {selectedVocab.masculine}<br/></span>
              )}
              {selectedVocab.feminine && selectedVocab.feminine.trim() !== '' && (
                <span>{translations[selectedLanguage].Feminine}: {selectedVocab.feminine}<br/></span>
              )}
              {selectedVocab.plural && selectedVocab.plural.trim() !== '' && (
                <span>{translations[selectedLanguage].Plural}: {selectedVocab.plural}<br/></span>
              )}  
              {selectedVocab.grammar_topic && selectedVocab.grammar_topic.trim() !== '' && (
                <span>
                  {translations[selectedLanguage].GrammarTopic} 
                  <span
                    style={{ fontWeight:'600',cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}
                    onClick={() => handleClick(selectedVocab.grammar_topic)}
                  >
                    {selectedVocab.grammar_topic}
                  </span> <br/>
                </span>
              )}      
              {selectedVocab.note_en && selectedVocab.note_en.trim() !== '' && (<span>{translations[selectedLanguage].Note}{getNote(selectedVocab, selectedLanguage)}<br/></span>)}
              <span style={{marginTop:'10px', marginBottom:'10px'}} className="french-word">{selectedVocab.translation}</span>
  </div>
              {(selectedVocab.part_of_speech_id === 2 || selectedVocab.part_of_speech_id === 3) && selectedVocab.tense_name_fr && (
                <div className="conjugation">
                  {!isObjectEmpty(selectedVocab.tense_type_fr) && selectedVocab.tense_type_fr !== '' && selectedVocab.tense_type_fr !== 'Indicatif' ? (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_type_fr}{' - '}{selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  ) : (
                    <span>
                      <br/>
                      <b onClick={() => handleClickVerbName()} style={{ cursor: 'pointer', color: '#66D5C1', textDecoration: 'underline' }}>
                        {selectedVocab.tense_name_fr}
                      </b>
                    </span>
                  )}
                  <br/>
                  {selectedVocab.conjugation && selectedVocab.conjugation !== '' &&                
                    <span>{selectedVocab.conjugation.split('<br/>').map((line, index, array) => (
                      <React.Fragment key={index}>
                        {line}
                        {index < array.length - 1 && <br />}
                      </React.Fragment>
                    ))}</span>}
                  
                </div>
              )}

              {isPopupOpen && (
                <div className="popup-link">
                  <div className="popup-link-content">
                  <button onClick={() => backButtonPopupClick()} className="back-button-popup"> <img src="/Icons/arrow-left-popup.svg" alt={translations[selectedLanguage].back} />  </button>
                  <button className="x-button" onClick={() => handleClose()}><b>X</b></button>
                  {isIPAClick ? 
                  (<div><br/><span dangerouslySetInnerHTML={{ __html: ipaContent }} /> </div>)
                  :
                  (<div><h4>{currentTopicName}</h4> 
                  <br/>
                  <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: grammarContent }} /> </div>)
                  }
                  </div>
                </div>
              )}
            </div>
        
        ) :       
        (          
        <div className="microphone" onClick={() => handleRecordingSentence()}>
            {recording ?         
            <button  className="recording-animation">
              {selectedLanguage === 'en' ? <img src="/Icons/stopEN.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'es' ? <img src="/Icons/stopES.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'pt_br' ? <img src="/Icons/stopPTBR.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'ar' ? <img src="/Icons/stopAR.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'fa' ? <img src="/Icons/stopFA.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'zh' ? <img src="/Icons/stopZH.svg" alt={translations[selectedLanguage].Stop} /> 
              : selectedLanguage === 'vi' ? <img src="/Icons/stopVI.svg" alt={translations[selectedLanguage].Stop} /> 
              : <img src="/Icons/stop.svg" alt={translations[selectedLanguage].Stop} /> }
            </button>          
            :
            <button ref={microphoneRef} className="microphone-button">
            <img src="/Icons/microphone.svg" alt={translations[selectedLanguage].Microphone} />
            </button>}
        </div>  
        )        
        }        
      
       <div style={{marginTop:'0px', textAlign:'right'}}>
          <button className="next-button" style={{marginTop:'10px'}} onClick={() => goToNext()}>
            <b>{translations[selectedLanguage].next}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
          </button>  
          <br/><br/><br/><br/>
        </div>
        </div>  
      <BottomMenuComponent steps={steps}/>
      </div> )
    )}    
    </div>
  );
};

export default SpeakingSentences;