import React, { createContext, useState, useContext, useEffect } from 'react';

const FavoriteVocab = createContext({
  favorites: new Set(),
  toggleFavorite: () => {}
});

export const useFavorites = () => useContext(FavoriteVocab);

export const FavoritesVocabProvider = ({ children }) => {
  const [favorites, setFavorites] = useState(new Set());

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || {};
    setFavorites(new Set(Object.keys(storedFavorites))); // Store fr keys in the favorites Set
  }, []);  

  const toggleFavorite = (vocab) => {
    setFavorites((prevFavorites) => {
      const updatedFavorites = new Set(prevFavorites);
      const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || {};

      if (updatedFavorites.has(vocab.fr)) {
          updatedFavorites.delete(vocab.fr); // Remove by vocab.fr
          delete storedFavorites[vocab.fr]; // Remove from localStorage
      } else {
          updatedFavorites.add(vocab.fr); // Add vocab.fr to favorites set
          
          // ✅ Store vocab details (fr & part_of_speech) in localStorage
          storedFavorites[vocab.fr] = {
              fr: vocab.fr,
              part_of_speech: vocab.part_of_speech || null // Only store if available
          };
      }
      localStorage.setItem('favorites', JSON.stringify(storedFavorites));

      return updatedFavorites;
    });
  };

  return (
    <FavoriteVocab.Provider value={{ favorites, toggleFavorite }}>
      {children}
    </FavoriteVocab.Provider>
  );
};
