import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// TO COMMIT FROM A MICROSOFT PC TO LINUX SERVER (GITHUB CODE SHOULD BE LINUX SERVER)
// CHANGE axios.get('http://localhost:5005   TO  axios.get('/api
// AND UNCOMMENT THE BELOW CODE
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5005', // Use .env file for different environments
});

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [levels, setLevels] = useState(() => {
    const storedLevels = localStorage.getItem('levels');
    return storedLevels ? JSON.parse(storedLevels) : [{level_id: 1, level_name: "Level 1"}, {level_id: 2, level_name: "Level 2"},{level_id: 3, level_name: "Level 3"},{level_id: 4, level_name: "Level 4"},{level_id: 5, level_name: "Level 5"},{level_id: 6, level_name: "Level 6"},{level_id: 7, level_name: "Level 7"},{level_id: 8, level_name: "Level 8"}];
  });


  const [topics, setTopics] = useState(() => {
    const storedTopics = localStorage.getItem('topics');
    return storedTopics ? JSON.parse(storedTopics) : [];
  });

  const [allLessons, setAllLessons] = useState(() => {
    const storedAllLessons = localStorage.getItem('allLessons');
    return storedAllLessons ? JSON.parse(storedAllLessons) : [];
  });
  const [allTopics, setAllTopics] = useState(() => {
    const storedAllTopics = localStorage.getItem('allTopics');
    return storedAllTopics ? JSON.parse(storedAllTopics) : [];
  });

  const [conversations, setConversations] = useState(() => {
    const storedConversations = localStorage.getItem('conversations');
    return storedConversations ? JSON.parse(storedConversations) : [];
  });

  const [sentencesForStudy, setSentencesForStudy] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudy');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  // a boolean array to distinguish speaking and listening sentences
  const [sentencesForStudyForSpeak, setSentencesForStudyForSpeak] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForStudyForSpeak');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });

  const [sentencesForLesson, setSentencesForLesson] = useState(() => {
    const storedSentences = localStorage.getItem('sentencesForLesson');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });

  const [lessons, setLessons] = useState(() => {
    const storedSentences = localStorage.getItem('lessons');
    return storedSentences ? JSON.parse(storedSentences) : [];
  });
  const [instructionForLesson, setInstructionForLesson] = useState(() => {
    const storedSentences = localStorage.getItem('instructionForLesson');
    return storedSentences ? JSON.parse(storedSentences) : '';
  });

  const [instructionForConversation, setInstructionForConversation] = useState(() => {
    const storedSentences = localStorage.getItem('instructionForConversation');
    return storedSentences ? JSON.parse(storedSentences) : '';
  });

  const [grammarGroups, setGrammarGroups] = useState([]);
  const [grammarTopics, setGrammarTopics] = useState([]);
  const [grammarContent, setGrammarContent] = useState('');
  const [pronunciationGroups, setPronunciationGroups] = useState([]);
  const [pronunciationTopics, setPronunciationTopics] = useState([]);
  const [pronunciationContent, setPronunciationContent] = useState('');
  const [myVocab, setMyVocab] = useState([]);

  useEffect(() => {
    localStorage.setItem('conversations', JSON.stringify(conversations));
  }, [conversations]);

  useEffect(() => {
    localStorage.setItem('sentencesForStudy', JSON.stringify(sentencesForStudy));
  }, [sentencesForStudy]);
  useEffect(() => {
    localStorage.setItem('sentencesForStudyForSpeak', JSON.stringify(sentencesForStudyForSpeak));
  }, [sentencesForStudyForSpeak]);
  
  useEffect(() => {
    localStorage.setItem('sentencesForLesson', JSON.stringify(sentencesForLesson));
  }, [sentencesForLesson]);

  useEffect(() => {
    localStorage.setItem('lessons', JSON.stringify(lessons));
  }, [lessons]);
  useEffect(() => {
    localStorage.setItem('instructionForLesson', JSON.stringify(instructionForLesson));
  }, [instructionForLesson]);  

  useEffect(() => {
    localStorage.setItem('instructionForConversation', JSON.stringify(instructionForConversation));
  }, [instructionForConversation]); 
  
  useEffect(() => {
    localStorage.setItem('levels', JSON.stringify(levels));
  }, [levels]);

  useEffect(() => {
    localStorage.setItem('topics', JSON.stringify(topics));
  }, [topics]);

  useEffect(() => {
    localStorage.setItem('allLessons', JSON.stringify(allLessons));
  }, [allLessons]);
  useEffect(() => {
    localStorage.setItem('allTopics', JSON.stringify(allTopics));
  }, [allTopics]);

  const sendFeedback = async (message) => {
    try {
      const response = await axios.post('http://localhost:5005/submit-feedback', { message });
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  const fetchLevels = async (language = '') => {
    try {
      const response = await axios.get('/api/levels', {
        params: { language },
      });
      setLevels(response.data);
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };

  const fetchTopicFromEnglishTopic = async (englishTopic = '', language = '') => {
    try {
      const response = await axios.get('/api/topicFromEnglishTopic', {
        params: { englishTopic, language },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };
  
  const fetchTopics = async (level = 0, language = '') => {
    try {
      const response = await axios.get('/api/topics', {
        params: { level , language},
      });
      setTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchAllLessons = async (language = '') => {
    try {
      const response = await axios.get('/api/allLessons', {
        params: { language},
      });
      setAllLessons(response.data);
    } catch (error) {
      console.error('Error fetching lessons:', error);
    }
  };
  const fetchAllTopics = async (language = '') => {
    try {
      const response = await axios.get('/api/allTopics', {
        params: { language},
      });
      setAllTopics(response.data);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  };

  const fetchLessonGroups = async (level = 0, language = '') => {
    try {     
        const response = await axios.get('/api/lessonGroups', {
          params: { level, language},
        });
        setLessons(response.data);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };
  
  const fetchLessonInstruction = async (level = 0, language = '', lesson_name = '') => {
    try {     
        const response = await axios.get('/api/lessonInstruction', {
          params: { level, language, lesson_name },
        });
        setInstructionForLesson(response.data);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchConversationInstruction = async (level = 0, language = '', topic = '') => {
    try {     
        const response = await axios.get('/api/convoInstruction', {
          params: { level, language, topic },
        });
        setInstructionForConversation(response.data);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };
  
  const fetchLessonSentences = async (level = 0, language = '', lesson_name = '') => {
    try {     
        const response = await axios.get('/api/lessonSentences', {
          params: { level, language, lesson_name },
        });
        setSentencesForLesson(response.data);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchConversations = async (language = '', topic = '') => {
    try {
        const response = await axios.get('/api/conversations', {
          params: { language, topic },
        });
        setConversations(response.data.conversations);
        setSentencesForStudy(response.data.sentencesForStudy);
        setSentencesForStudyForSpeak(response.data.sentencesForStudyForSpeak);
    } catch (error) {
      console.error('Error fetching sentences:', error);
    }
  };

  const fetchUpdatedVocab = async (vocabTerm, language = '') => {
    try {
      const response = await axios.get('/api/updateVocab', {
        params: { vocabTerm , language},
      });  
      return response.data;
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };

  const fetchUpdatedMatchedPhrase = async (matchedPhrase) => {
    try {
      const response = await axios.get('/api/updateMatchedPhrase', {
        params: { matchedPhrase },
      });  
      return response.data;
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };

  const fetchMyVocab = async (favoriteArray, language = '') => {
    try {
      const response = await axios.get('/api/myVocab', {
        params: { favoriteArray, language },
      });
      setMyVocab(response.data);
    } catch (error) {
      console.error('Error updating vocabulary:', error);
    }
  };
  
  const fetchGrammarGroups = async (language = '') => {
    const response = await axios.get('/api/grammar-groups/', {
      params: { language },
    });
    setGrammarGroups(response.data);
  };

  const fetchGrammarTopics = async (groupName = '', language = '') => {
    const response = await axios.get('/api/grammar-topics/', {
      params: { groupName, language },
    });
    setGrammarTopics(response.data);
  };
    
  const fetchGrammarContent = async (topicName = '', language = '') => {
    const response = await axios.get('/api/grammar-content', {
      params: { topicName,  language },
    });
    setGrammarContent(response.data);
  };

  const fetchPronunciationGroups = async (language = '') => {
    const response = await axios.get('/api/pronunciation-groups/', {
      params: { language },
    });
    setPronunciationGroups(response.data);
  };

  const fetchPronunciationTopics = async (groupId = 0, language = '') => {
    const response = await axios.get('/api/pronunciation-topics/', {
      params: { groupId, language },
    });
    setPronunciationTopics(response.data);
  };
    
  const fetchPronunciationContentFromTopic = async (topicId = 0, language = '') => {
    const response = await axios.get('/api/pronunciation-content-from-topic', {
      params: { topicId, language },
    });
    setPronunciationContent(response.data);
  };

  const fetchPronunciationContentFromGroup = async (groupId = 0, language='') => {
    const response = await axios.get('/api/pronunciation-content-from-group', {
      params: { groupId, language },
    });
    setPronunciationContent(response.data);
  };

  return (
    <DataContext.Provider
      value={{ setInstructionForConversation, allLessons, fetchAllLessons, setTopics, setLessons, fetchUpdatedMatchedPhrase, sendFeedback,  levels, fetchLevels, topics, fetchTopics, lessons, instructionForLesson, setInstructionForLesson, sentencesForLesson, setSentencesForLesson, sentencesForStudy, sentencesForStudyForSpeak, conversations, fetchLessonGroups, fetchLessonInstruction, instructionForConversation, fetchConversationInstruction, fetchLessonSentences, fetchConversations, fetchUpdatedVocab, grammarGroups, fetchGrammarGroups, grammarTopics, fetchGrammarTopics, grammarContent, setGrammarContent, fetchGrammarContent, pronunciationGroups, fetchPronunciationGroups, pronunciationTopics, fetchPronunciationTopics, pronunciationContent, setPronunciationContent, fetchPronunciationContentFromTopic, fetchPronunciationContentFromGroup, fetchTopicFromEnglishTopic, fetchAllTopics, allTopics, setSentencesForStudy, setSentencesForStudyForSpeak,  setConversations, myVocab, fetchMyVocab, setGrammarTopics, setPronunciationTopics }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;