import React, { useContext, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { useRecordUser } from '../Reusables/RecordUser';

const ConversationInstruction = () => {
  const navigate = useNavigate();
  const {  topic, level} = useUserAnswers();
  const { topics, fetchTopics, sentencesForStudy, fetchConversations, instructionForConversation, fetchConversationInstruction} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const { stopAudioRecordingRedbutton} = useRecordUser();
  let steps = [];

  useEffect(() => {
    return () => {
      stopAudioRecordingRedbutton(); 
    };
  }, []); 
  
  useEffect(() => {    
    if(topic !== '' && instructionForConversation === ''){
        fetchConversationInstruction(level, selectedLanguage, topic);
        fetchConversations(selectedLanguage, topic);
    }    
    if (topics.length === 0){
      fetchTopics(level, selectedLanguage);
    }    
  }, [topic]);

  return (   
    <div className='page-container'>
      <div className="top-row">
        <button onClick={() => navigate('/topic-selection', { state: { practiceConvo: true } })} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{topic}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className='grammar-content'>
        <div >          
          <br/><br/><div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: instructionForConversation }} />
          <br/><div style={{fontStyle:'italic'}} dangerouslySetInnerHTML={{ __html: translations[selectedLanguage].ConversationInstruction }} />
        </div>
        
        <div style={{marginTop:'0px', textAlign:'right'}}>
            <br/>
            <button data-exercise={topic} data-language={selectedLanguage} data-level={level} className="lesson-next-button" style={{marginTop:'10px'}} onClick={() => navigate('/speaking-sentences', { state: { index: 0 } })}>
            <b>{translations[selectedLanguage].next}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
            </button>  
            <br/><br/><br/><br/>
        </div>    
      </div>       
      <BottomMenuComponent steps={steps}/>
    </div>    
  );
};

export default ConversationInstruction;
