import React, { useContext,  useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import { useRecordUser } from '../Reusables/RecordUser';
import './Grammar.css';
import '../Topic/TopicSelection.css';

const GrammarGroups = () => {  
  const navigate = useNavigate();
  const { grammarGroups, fetchGrammarGroups, grammarTopics, fetchGrammarTopics, setGrammarTopics, setGrammarContent} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const [expandedTopicIndex, setExpandedTopicIndex] = useState(null); 
  const { stopAudioRecordingRedbutton} = useRecordUser();

  useEffect(() => {    
    fetchGrammarGroups(selectedLanguage);
    return () => {
      stopAudioRecordingRedbutton(); 
    };
  }, []);
 
  const handleGroupClick = (index, groupName) => {
    setExpandedTopicIndex(index === expandedTopicIndex ? null : index);
    setGrammarTopics([]);
    fetchGrammarTopics(groupName, selectedLanguage); 
  };

  const handleClickGrammarTopic = (topicName) => {   
    setGrammarContent([]);
    navigate('/grammar-topic', { state: { topicName : topicName } });
  };
  return (
    <div className="page-container">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].Grammar}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className="study-body">
        {grammarGroups.map((grammarGroup, index) => (
            <div key={index}  className="topics">            
              <button className="grammar-button" onClick={() => handleGroupClick(index, grammarGroup.group_name_en)} >
                <div style={{marginRight:"auto"}}>{grammarGroup.group_name}</div>
                <div style={{marginLeft:"auto"}}>{expandedTopicIndex === index ? ( 
                  <img src="/Icons/arrow-up.svg" alt={translations[selectedLanguage].collapse} />
                ) : (
                  <img src="/Icons/arrow-down.svg" alt={translations[selectedLanguage].expand} />
                )}</div>
              </button>
              {expandedTopicIndex === index && ( 
                <div>
                  {grammarTopics.map((grammarTopic, index) => (
                    <div key={index} className="grammar-topic-options">            
                      <button className="grammar-option" onClick={() => handleClickGrammarTopic(grammarTopic.name)} >
                        <span>  {grammarTopic.name}</span>
                        <img src="/Icons/arrow-right.svg" alt={translations[selectedLanguage].next} className="arrow-right" />                  
                      </button>
                    </div>            
                  ))}                 
                </div>
              )}
  
              {index < grammarGroups.length - 1 && <hr />}
            </div>            
        ))}
      </div>   
    </div>
  );
};

export default GrammarGroups;
