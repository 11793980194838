import React, { useState, useRef } from 'react';
import './Bryan.css';

// Define categories and their respective display types
const categories = {
    "Show activities": "grid",
    "School activities": "grid",
    
    "Toys": "single",
    "Feelings": "single",
    "More or finished": "single",
    "Family members": "single",
    "Choose an activity": "single"
};

const images = {
    "Show activities": [
        {file: "Show activities/brushteeth.png", text: "brush teeth"},
        {file: "Show activities/eatbymouth.png", text: "eat by mouth"},
        {file: "Show activities/lunch.png", text: "milk"},
        {file: "Show activities/massage.png", text: "massage"},
        {file: "Show activities/movingstroller.png", text: "moving stroller"},
        {file: "Show activities/schoolbus.png", text: "school bus"},
        {file: "Show activities/sleep time.jpg", text: "sleep time"},
        {file: "Show activities/walking.png", text: "walking"},
        {file: "Show activities/watch cartoon.jpg", text: "watch cartoon"},
        {file: "Show activities/call.png", text: "make a call"}
    ],    
    "School activities": [
        {file: "School activities/circle time.PNG", text: "circle time"}, 
        {file: "School activities/computer.png", text: "computer"},
         {file: "School activities/eatbymouth.png", text: "eat"},
        {file: "School activities/lunch.PNG"},
        {file: "School activities/movingstroller.png",  text: "moving stroller"},
        {file: "School activities/play.PNG"},
        {file: "School activities/schoolbus.PNG"},
        {file: "School activities/sleep time.jpg",  text: "nap"},
        {file: "School activities/walking.PNG"}
    ],

    "Toys": [
        {file: "Toys/ball raddle big.jpg",  audio: "big raddle.mp3"},
        {file: "Toys/ball raddle small.PNG",  audio: "small raddle.mp3"},
        {file: "Toys/btoy.jpg",  audio: "btoy.mp3"},
        {file: "Toys/wheel.jpg",  audio: "wheel.mp3"},
        {file: "Toys/drum.jpg",  audio: "drum.mp3"},
        {file: "Toys/mirror piano.jpg",  audio: "mirror piano.mp3"},
        {file: "Toys/pyramid ring.jpg",  audio: "pyramid ring.mp3"},
        {file: "Toys/spinning.jpg",  audio: "spinning.mp3"},
        {file: "Toys/ukulele.jpg",  audio: "ukulele.mp3"},
        {file: "Toys/hapecutie.jpg",  audio: "doll.mp3"},
        {file: "Toys/press down.jpg",  audio: "press down.mp3"},
        {file: "Toys/rolling piano.jpg",  audio: "rolling piano.mp3"},
        {file: "Toys/tiger piano.jpg",  audio: "tiger piano.mp3"},
        {file: "Toys/wrist bell.jpg",  audio: "wrist bell.mp3"}
    ],
    "Feelings": [
        {file: "Feelings/angry.png", audio: "angry.mp3"},
        {file: "Feelings/bored.jpg", audio: "bored.mp3"},
        {file: "Feelings/cold.svg", audio: "cold.mp3"},
        {file: "Feelings/hot.svg", audio: "hot.mp3"},
        {file: "Feelings/happy.svg",  audio: "happy.mp3"},
        {file: "Feelings/sad.png", audio: "sad.mp3"},
        {file: "Feelings/pee.png", audio: "pee.mp3"},
        {file: "Feelings/kaka.png", audio: "kaka.mp3"},
        {file: "Feelings/pain.png", audio: "pain.mp3"},
        {file: "Feelings/sleepy.png", audio: "sleepy.mp3"}
    ],
    "More or finished": [
        {file: "More or finished/more.png",  audio: "more.mp3"},
        {file: "More or finished/finished.png", audio: "finished.mp3"}],
    "Family members": [ 
        {file: "Family members/mom.png", audio: "mom.mp3"},
        {file: "Family members/aunt.png", audio: "aunt.mp3"}, 
        {file: "Family members/Coca.png", audio: "coca.mp3"},
        {file: "Family members/Bim.png", audio: "bim.mp3"}, 
        {file: "Family members/grandpa.png", audio: "grandpa.mp3"}, 
        {file: "Family members/grandma.png", audio: "grandma.mp3"}
    ],
    "Choose an activity": [
        {file: "Choose an activity/books.png", audio: "books.mp3"},         
        {file: "Choose an activity/diaper.png", audio: "diaper.mp3"}, 
        {file: "Choose an activity/eatbymouth.png",audio: "eat.mp3"}, 
        {file: "Choose an activity/massage.png", audio: "massage.mp3"}, 
        {file: "Choose an activity/movingstroller.png", audio: "walk.mp3"}, 
        {file: "Choose an activity/walking.PNG", audio: "walking.mp3"}, 
        {file: "Choose an activity/watch cartoon.jpg",audio: "cartoon.mp3"}, 
        {file: "Choose an activity/play.png", audio: "play.mp3"}, 
        // {file: "Choose an activity/sleep time.jpg", audio: "sleep time.mp3"}, 
        {file: "Choose an activity/tiltingstroller.jpg", audio: "tiltingstroller.mp3"}
    ]
};


const Bryan = () => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const exitButtonTimer = useRef(null);
    const audioRef = useRef(null);
   
    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        if(category !== "grid" ){
            if (images[category][0].audio) {
                audioRef.current.src = `/Audio/Bryan/${images[category][0].audio}`;
                audioRef.current.load();
                audioRef.current.play().catch(error => console.error("Audio play failed:", error));
            }
        }
        setCurrentIndex(0); // Reset index
    };

    const handleImageClick = (image) => {
        console.log(image);
        if (image.audio) {
            audioRef.current.src = `/Audio/Bryan/${image.audio}`;
            audioRef.current.load();
            audioRef.current.play().catch(error => console.error("Audio play failed:", error));
        }
    };

    const handleNextImage = () => { 
        const nextIndex = (currentIndex + 1) % images[selectedCategory].length;
        setCurrentIndex(nextIndex);
        const nextImage = images[selectedCategory][nextIndex];

        if (nextImage.audio) {
            audioRef.current.src = `/Audio/Bryan/${nextImage.audio}`;
            audioRef.current.load();
            audioRef.current.play().catch(error => console.error("Audio play failed:", error));
        }
    };

    const handleExit = () => {
        if (categories[selectedCategory] === "grid") {
            setSelectedImage(null);
        } else {
            setSelectedCategory(null);
            setSelectedImage(null);
        }
    };

    const handlePressStart = () => {
        exitButtonTimer.current = setTimeout(() => {
            handleExit(); // only called when showing series of images
        }, 1000); // press 1 second to close if children make choices
    };

    const handlePressEnd = () => {
        clearTimeout(exitButtonTimer.current);        
    };

    return (
        <div className="bryan-app-container">
            <audio ref={audioRef} />
            {selectedImage ? ( // show an activity, no interaction
                <div className="bryan-fullscreen-view" >
                    <p className="image-text">{selectedImage.text}</p> 
                    <button className="bryan-exit-btn"  onClick={() => setSelectedImage(null)}>X</button>
                    <img  src={`/Icons/Bryan/${selectedImage.file}`} alt="Full View" className="bryan-fullscreen-image" />                    
                </div>
            ) : selectedCategory ? ( // show activity grid
                categories[selectedCategory] === "grid" ? (
                    <>
                    <button className="bryan-exit-btn" onClick={() => setSelectedCategory(null)}>X</button>                   
                    <div className="bryan-grid-container">
                        {images[selectedCategory].map((img, index) => (
                            <img key={index} src={`/Icons/Bryan/${img.file}`} alt={selectedCategory} className="bryan-thumbnail" onClick={() => setSelectedImage(img)} />                            
                        ))}                        
                    </div>                    
                    </>
                ) : (   // show series of images, interactive with children
                    <div className="bryan-single-image-view">
                        <img onClick={() => handleImageClick(images[selectedCategory][currentIndex])} src={`/Icons/Bryan/${images[selectedCategory]?.[currentIndex].file}`} alt={selectedCategory} className="bryan-display-image" />
                        <button className="bryan-next-area" onClick={handleNextImage}>
                            <img src={"/Icons/Bryan/arrow-right-bryan.svg"} alt="Next" className="bryan-next-btn" />
                        </button>
                        <button className="bryan-exit-btn" 
                            onMouseDown={handlePressStart} 
                            onMouseUp={handlePressEnd}>
                            X
                        </button>
                    </div>
                )
            ) : (
                <div className="bryan-button-container">
                    {Object.keys(categories).map((category, index) => (
                        <button key={index} onClick={() => handleCategoryClick(category)}>
                            {category}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Bryan;
