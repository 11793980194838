
import React, { useState, useEffect, useRef, useContext, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { frToAudio, frToAudioLesson, translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { useUserAnswers } from '../Practice/UserAnswers';
import { useRecordUser } from '../Reusables/RecordUser';

const SummaryPage = () => {
  const navigate = useNavigate();  
  const { setInstructionForLesson, lessons, fetchTopics, fetchLessonGroups, sentencesForStudy, sentencesForLesson, setSentencesForLesson } = useContext(DataContext);
  const { topic, lessonEn, lessonIndex, updateLessonIndex, updateLesson, updateLessonEn, level, updateLevel, englishTopic} = useUserAnswers();
  const { selectedLanguage } = useLanguage();
  const { isPlaying, isPlayingSlower, playPause, slowerPause, currentAudioFile} = usePlayAudio(); 
  const { stopAudioRecordingRedbutton} = useRecordUser();
  const [clickNextLesson, setClickNextLesson] = useState(false);
  const [gotoLevel2, setGotoLevel2] = useState(false);

  useEffect(() => {
    return () => {
      stopAudioRecordingRedbutton(); 
    };
  }, []); // Empty dependency array means this effect runs only once, and the cleanup runs when the component unmounts
  
  const goBack = useCallback(() => {
    navigate(-1);
  });
  useEffect(() => {
    const handleKeyDown = (event) => {      
      if (event.key === "ArrowLeft") {
        goBack(); 
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
  
    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goBack]);

  // Helper functions to navigate to the specific sentence
  const navigateToStudySentence = (index) => {
    navigate('/speaking-sentences', { state: { index } });
  };

  const goToPractice = () => {
    navigate('/practice');
  };

  useEffect(() => {    
    if (clickNextLesson && sentencesForLesson.length === 0){
      setClickNextLesson(false);
      navigate('/lesson-instruction');
    }
  }, [clickNextLesson]);

  useEffect(() => {    
    // if (gotoLevel2 && sentencesForLesson.length === 0 && topics.length === 0 && lessons.length === 0){
    if (gotoLevel2){  
      navigate('/topic-selection');
    }
  }, [gotoLevel2]);

  const nextLessonOrPracticeConvo = () => {
    if (lessonIndex === lessons.length - 1){
      if (level === 1){     // go to level 2
        updateLevel(2);
        fetchTopics(2, selectedLanguage);
        fetchLessonGroups(2, selectedLanguage);
        // setSentencesForLesson([]);       
        setGotoLevel2(true);
      } else {
        navigate('/topic-selection', { state: { practiceConvo: true } }); // to study conversation
      }      
    } else {     
      setSentencesForLesson([]);          // next lesson  
      setInstructionForLesson('');
      updateLessonIndex(lessonIndex + 1);
      updateLesson(lessons[lessonIndex + 1].lesson_name);
      updateLessonEn(lessons[lessonIndex + 1].lesson_name_en);
      setClickNextLesson(true);   
    }
  };

  /********** TRANSLATION **********/ 
  const [showTranslationSpeak, setShowTranslationSpeak] = useState(true);
  const [translationText, setTranslationText] = useState('');
  const [translationSpeakIndex, setTranslationSpeakIndex] = useState(null);
  
  const translateSpeak = (index, text) => {
    if (showTranslationSpeak && translationSpeakIndex === index) {
      setShowTranslationSpeak(false);
      setTranslationText(''); // Clear translation text
    } else {
      setShowTranslationSpeak(true);
      setTranslationText(text); // Set translation text
      setTranslationSpeakIndex(index);
    }
  };
 
  /********** POPUP INSTRUCTION **********/
  const sentenceRef = useRef(null);
  const practiceRef = useRef(null);
  let steps = [];

  if (topic !== ''){
    steps = [
      { ref: sentenceRef, label: translations[selectedLanguage].Expandablesentence, text: translations[selectedLanguage].Clickthissentencetolearn } ,
      { ref: practiceRef, label: translations[selectedLanguage].PracticeLabel, text: translations[selectedLanguage].Practicewhenyouareready}   
    ];
  } else {
    steps = [
      { ref: sentenceRef, label: translations[selectedLanguage].Expandablesentence, text: translations[selectedLanguage].Clickthissentencetolearn } 
    ];
  }

  return (
    <div className="page-container">    
    {(topic === '' && lessonEn === '') ? (
      <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].ChooseALesson}</button>
    ) : ( 
      topic !== '' ? 
        <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
          <h3>{translations[selectedLanguage].summary}</h3>
          <BurgerMenuComponent />
        </div>      
        <div  className="summary-body">      
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="page-instruction">{translations[selectedLanguage].SummaryInstruction}</div>         
          {sentencesForStudy.map((entry, index) => (
            <div key={index} className={`summary-sentences ${showTranslationSpeak && translationSpeakIndex === index ? 'summary-active' : ''}`}>
              <p  ref={sentenceRef} onClick={() => translateSpeak(index, entry.translation)}>
                {entry.fr}
              </p>
              {showTranslationSpeak && translationSpeakIndex === index && (
                <div>
                  <div className="buttons-in-summary">
                    <div>
                      <button className="speaker-button-summary" onClick={() => playPause(frToAudio(entry.fr, level, englishTopic))}>
                        <img src={isPlaying && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? "/Icons/pause-summary.svg" : "/Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                      </button>
                      <button className="slowdown-button-summary" onClick={() => slowerPause(frToAudio(entry.fr, level, englishTopic))}>
                        <img src={isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? "/Icons/pause-summary.svg" : "/Icons/turtle-summary.svg"} alt={isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                      </button> 
                    </div>                 
                    <button style={{transform: 'translateY(-5px)'}} className="next-button" onClick={() => navigateToStudySentence(index)}>
                      <b>{translations[selectedLanguage].study}</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
                    </button>  
                  </div>
                    {isPlayingSlower && currentAudioFile === frToAudio(entry.fr, level, englishTopic) && <PlaybackRateControl />}                 
                    <p dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</p>                  
                </div>
              )}
            </div>
          ))}
        </div>

        {<button ref={practiceRef} style={{margin:'-30px auto 100px auto'}} className="practice-button" onClick={() => goToPractice()}><b>{translations[selectedLanguage].PRACTICE}</b></button>}
              
        <BottomMenuComponent steps={steps}/>
        </div>     

         :

         <div>
          <div className="top-row">
            <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
            <h3>{translations[selectedLanguage].summary}</h3>
            <BurgerMenuComponent />
          </div>      
          <div  className="summary-body">      
            {/* <div style={{marginLeft:'40px'}} ref={sentenceRef}></div> */}
            {sentencesForLesson.map((entry, index) => (
              <div key={index} className={`summary-sentences ${showTranslationSpeak && translationSpeakIndex === index ? 'summary-active' : ''}`}>
                <p  ref={sentenceRef} onClick={() => translateSpeak(index, entry.translation)}>
                  {entry.fr}
                </p>
                {showTranslationSpeak && translationSpeakIndex === index && (
                  <div>
                    <div className="buttons-in-summary">
                      <div>
                        <button className="speaker-button-summary" onClick={() => playPause(frToAudioLesson(entry.fr, lessonEn, level))}>
                          <img src={isPlaying && currentAudioFile === frToAudioLesson(entry.fr, lessonEn, level) ? "/Icons/pause-summary.svg" : "/Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudioLesson(entry.fr, lessonEn, level) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                        </button>
                        <button className="slowdown-button-summary" onClick={() => slowerPause(frToAudioLesson(entry.fr, lessonEn, level))}>
                          <img src={isPlayingSlower && currentAudioFile === frToAudioLesson(entry.fr, lessonEn, level) ? "/Icons/pause-summary.svg" : "/Icons/turtle-summary.svg"} alt={isPlayingSlower && currentAudioFile === frToAudioLesson(entry.fr, lessonEn, level) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                        </button> 
                      </div>                 
                      <button style={{transform: 'translateY(-5px)'}} className="summary-next-button" onClick={() => navigateToStudySentence(index)}>
                        <b>{translations[selectedLanguage].study}</b>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
                      </button>  
                    </div>
                      {isPlayingSlower && currentAudioFile === frToAudioLesson(entry.fr, lessonEn, level) && <PlaybackRateControl />}
                      <p dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</p>                  
                  </div>
                )}
              </div>
            ))}
          </div>
          
          <button style={{margin:'-30px auto 100px auto'}} className="practice-button" onClick={() => nextLessonOrPracticeConvo()}><b>
            {(lessonIndex === (lessons.length - 1) && level === 1) ? translations[selectedLanguage].NEXTLESSONLEVEL2
              :
              (lessonIndex === (lessons.length - 1) && level > 1) ?
              translations[selectedLanguage].PRACTICECONVERSATION
              :
              translations[selectedLanguage].NEXTLESSON
            }
            </b></button>          
         
          <BottomMenuComponent steps={steps}/>
        </div> 
        )} 
    </div>
  );
};

export default SummaryPage;