import React, { useState, useContext, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import { usePlayAudio } from '../Reusables/PlayAudio';
import { useRecordUser } from '../Reusables/RecordUser';

const LessonInstruction = () => {
  const navigate = useNavigate();  
  // const {  fromPreviousLesson } = location.state || { fromPreviousLesson: false};  
  const { fetchLessonGroups, sentencesForLesson, fetchLessonInstruction, fetchLessonSentences, instructionForLesson, grammarContent, setGrammarContent, fetchGrammarContent } = useContext(DataContext);
  const {  lesson, level} = useUserAnswers();
  const { selectedLanguage } = useLanguage();
  let steps = [];
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [topicHistory, setTopicHistory] = useState([]);
  const [currentTopicName, setCurrentTopicName] = useState('');
  const {playPause} = usePlayAudio();
  const { stopAudioRecordingRedbutton} = useRecordUser();

  useEffect(() => {
    return () => {
      stopAudioRecordingRedbutton(); 
    };
  }, []); 
  
  useEffect(() => {    
    if (lesson !== '' && instructionForLesson === '') {
      fetchLessonInstruction(level, selectedLanguage, lesson);     
      fetchLessonGroups(level, selectedLanguage);  
      fetchLessonSentences(level, selectedLanguage, lesson); 
    }
  }, [lesson]);

  // Function to go back to the previous topic
  const backButtonPopupClick = () => {
    if (topicHistory.length > 0) {      // this is different from SpeakingSentences
      const previousTopic = topicHistory[topicHistory.length - 1];
      setTopicHistory(topicHistory.slice(0, -1)); // Remove the last topic from the stack
      setCurrentTopicName(previousTopic);
      fetchGrammarContent(previousTopic, selectedLanguage); // Fetch the previous topic's content
    } else {
      setIsPopupOpen(false);
      setGrammarContent('');
    }
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    setGrammarContent('');
  };

// useEffect to handle adding event listeners after content is loaded
useEffect(() => {
    const links = document.querySelectorAll('.link');
    const handleClickWrapper = (event) => {
      event.preventDefault(); // Prevent default link behavior
      const newTopicName = event.target.getAttribute('data-topic');
      if(grammarContent !== ''){
        setTopicHistory(prevHistory => [...prevHistory, currentTopicName]);
      }  
      setCurrentTopicName(newTopicName);
      fetchGrammarContent(newTopicName, selectedLanguage); 
      setIsPopupOpen(true);
    };

    links.forEach(link => {
      link.addEventListener('click', handleClickWrapper);
    });

    // Clean up event listeners on component unmount
    return () => {
      links.forEach(link => {
        link.removeEventListener('click', handleClickWrapper);
      });
    };
}, [instructionForLesson, grammarContent]);

useEffect(() => {
  const buttons = document.querySelectorAll('.listen-button');
  
  const handlePlayPause = (event) => {
    const audio = event.currentTarget.getAttribute('data-audio');
    playPause(audio);      
  };

  buttons.forEach(button => {
    button.addEventListener('click', handlePlayPause);
  });
}, [instructionForLesson]);

  return (   
    <div className='page-container'>
      <div className="top-row">
        <button onClick={() => navigate('/topic-selection')} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
        <h3>{lesson}</h3>
        <div><BurgerMenuComponent /></div>  
      </div> 
      <div className='grammar-content'>
        <div >
          <br/> <br/>
          <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: instructionForLesson }} />
        </div>
        
        <div style={{marginTop:'0px', textAlign:'right'}}>
            <br/>
            <button data-exercise={lesson} data-language={selectedLanguage} data-level={level} className="lesson-next-button" style={{marginTop:'10px'}} onClick={() => navigate('/speaking-sentences', { state: { index: 0 } })}>
            <b>{translations[selectedLanguage].next}</b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <img src={"/Icons/arrow-right-aqua.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
            </button>  
            <br/><br/><br/><br/>
        </div>    
      </div>  
      
      {isPopupOpen && (
        <div className="popup-link">
          <div className="popup-link-content">
          <button onClick={() => backButtonPopupClick()} className="back-button-popup"> <img src="/Icons/arrow-left-popup.svg" alt={translations[selectedLanguage].back} />  </button>
          <button className="x-button" onClick={() => handleClose()}><b>X</b></button>
          <div><h4>{currentTopicName}</h4> 
          <br/>
          <span dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} dangerouslySetInnerHTML={{ __html: grammarContent }} /> </div>          
          </div>
        </div>
      )}
      <BottomMenuComponent steps={steps}/>
    </div>    
  );
};

export default LessonInstruction;
