import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../Reusables/DataContext';
import { useUserAnswers  } from './UserAnswers';
import { showSweetAlert, showLastSweetAlert, getMatchPercentage, frToAudio, translations, getPointsEarned } from '../Reusables/Utilities';
import { usePlayAudio, PlaybackRateControl } from '../Reusables/PlayAudio';
import { useRecordUser } from '../Reusables/RecordUser';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './Practice.css'; 

const PracticeSpeaking = () => {
  let navigate = useNavigate();    
  const { conversations} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const { speakConversationIndex, level, englishTopic, resetUserSpeakAnswers, updatePoints, updateUserSpeakAnswer, updateSpeakMatchPercentage, updateSpeakMatchSuggestResponse, updateUserReports} = useUserAnswers();
  const [currentIndex, setCurrentIndex] = useState(0); // which pair of application-user 
  const [visiblePairs, setVisiblePairs] = useState([]);
  const {isPlaying, isPlayingSlower, playPause, stopAudio, slowerPause, currentAudioFile} = usePlayAudio();  
  const [firstAIText, setFirstAIText] = useState('');
  const [endWithAI, setEndWithAI] = useState(false);
  const [startWithUser, setStartWithUser] = useState(false);
  
  useEffect(() => {
    if (conversations.length > 0 ){
      setVisiblePairs([conversations[speakConversationIndex].pairs[currentIndex]]); // Start with the first pair visible
      // setSituationDescription(conversations[speakConversationIndex].description);      
      if(conversations[speakConversationIndex].startWithUser){
        setStartWithUser(true);
        setFirstAIText(conversations[speakConversationIndex].pairs[1].application.fr || '');

      } else {
        setFirstAIText(conversations[speakConversationIndex].pairs[0].application.fr || '');
      }
      if(conversations[speakConversationIndex].endWithAI){
        setEndWithAI(true);
      }      
    }
  }, []);
      
  useEffect(() => {
    if (conversations.length > 0){
      setCurrentIndex(0);    
      setVisiblePairs([conversations[speakConversationIndex].pairs[0]]); 
      setTranscriptionIndex(null);
      setTranscriptionText('');
      setTranslationIndex(null);
      setTranslationText('');
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    }
  }, [speakConversationIndex]);

  useEffect(() => {
    if (currentIndex === 0){
      resetUserSpeakAnswers(speakConversationIndex);
    } 
    if (conversations.length !== 0){
      if (endWithAI && currentIndex === (conversations[speakConversationIndex].pairs.length - 1)) {
        setTranscription('');
        const timer = setTimeout(() => {
          navigate('/pronunciation-report'); 
        }, 10000);
        return () => clearTimeout(timer);
      }
    }
  }, [currentIndex]); 

  const goBack = () => {
    setTranscription('');
    navigate(-1);    
  };
    
  const handleStartRecording = () => {   
    stopAudio();    
    if (endWithAI && currentIndex === (conversations[speakConversationIndex].pairs.length - 1)) {  
      navigate('/pronunciation-report'); 
    } else if (!processingSpeech.current){      
      startRecording(selectedLanguage);
    }        
  };    
  
  const nextConversationPair = () => {
    const nextIndex = currentIndex + 1; 
    if (nextIndex < conversations[speakConversationIndex].pairs.length) {
      setVisiblePairs(prevVisiblePairs => [...prevVisiblePairs, conversations[speakConversationIndex].pairs[nextIndex]]);    
      setCurrentIndex(nextIndex);
      playPause(frToAudio(conversations[speakConversationIndex].pairs[nextIndex].application.fr, level, englishTopic));           // play next application sentence once
    } 
  };  

  //auto scroll to the latest conversation pair
  const messagesEndRef = useRef(null);
  useEffect(() => {
    const scrollToEnd = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Use setTimeout to ensure the SweetAlert has rendered before scrolling
    const timeoutId = setTimeout(scrollToEnd, 100);

    return () => clearTimeout(timeoutId);
  }, [visiblePairs]);

  /********** TRANSCRIPTION **********/ 
  const [transcriptionText, setTranscriptionText] = useState('');
  const [transcriptionIndex, setTranscriptionIndex] = useState(null); 

  const transcribeApplication = (text, index) => {
    if (transcriptionIndex === index) {
      setTranscriptionIndex(null);
      setTranscriptionText(''); // Clear transcription text
    } else {
      setTranscriptionIndex(index);
      setTranscriptionText(text); // Set transcription text
    }
  };
  
  /********** TRANSLATION **********/ 
  const [translationText, setTranslationText] = useState('');
  const [translationIndex, setTranslationIndex] = useState(null); 
  const [userTranslationText, setUserTranslationText] = useState('');
  const [userTranslationIndex, setUserTranslationIndex] = useState(null); 
  const [userTranslationIdx, setUserTranslationIdx] = useState(null); 

  const translateApplication = (text, index) => {
    if (translationIndex === index) {
      setTranslationIndex(null);
      setTranslationText(''); 
    } else {
      setTranslationIndex(index);
      setTranslationText(text);
    }
  };

  const translateSuggestAnswer = (text, index, idx) => {
    if(userTranslationIndex === index && userTranslationIdx === idx){
      setUserTranslationIndex(null);
      setUserTranslationIdx(null);
      setUserTranslationText(''); 
    } else{
      setUserTranslationIndex(index);
      setUserTranslationIdx(idx);
      setUserTranslationText(text);      
    }
  };
  
  /********** USER SPEECH **********/   
  const {transcription, setTranscription, recording, startRecording, stopRecording, processingSpeech} = useRecordUser();
  const [highestMatch, setHighestMatch] = useState([0]);
  const [bestMatchSentence, setBestMatchSentence] = useState(['']);
  const previousTranscriptionRef = useRef(transcription);
  const userInteractionRef = useRef(false);
 
  useEffect(() => {      
    if (transcription !== ''){ 
      console.log('detected sentence = ' + transcription); 
      previousTranscriptionRef.current = transcription;
      if(!userInteractionRef.current){
          userInteractionRef.current = true;
      }
      if(transcription.replace(/[.,?!'"']/g, '') !== '') {
          findBestMatch();
      }
    }    
  }, [transcription]);  
 
  function findBestMatch() {
    if(visiblePairs.length > currentIndex && visiblePairs[currentIndex] && visiblePairs[currentIndex].suggestResponses){
      const sentencesA = visiblePairs[currentIndex].suggestResponses;
      let newHighestMatches = [...highestMatch]; // Clone the current state to modify
  
  
      sentencesA.forEach(sentence => {
          let matchPercentage = getMatchPercentage(transcription, sentence.fr);        
               
          if (currentIndex >= newHighestMatches.length) {
            newHighestMatches.push(0);  // Extend the highestMatch array if new answer          
          }
  
  
          // Update the highest match if the new match is better
          if (matchPercentage >= newHighestMatches[currentIndex]) {
            newHighestMatches[currentIndex] = matchPercentage;
            setBestMatchSentence(sentence.fr);

            //update points
            updatePoints(getPointsEarned(sentence.fr, matchPercentage));
          }            
      });    
     
      // Update the state only once after all calculations are done
      setHighestMatch(newHighestMatches);
    }    
  }  

  useEffect(() => {  
      if (userInteractionRef.current && transcription !== '') {
        updateUserSpeakAnswer(speakConversationIndex, currentIndex, transcription.replace(/["]/g, '').replace(/'/g, "\'"));
        updateSpeakMatchPercentage(speakConversationIndex, currentIndex, highestMatch[currentIndex]);
        updateSpeakMatchSuggestResponse(speakConversationIndex, currentIndex, bestMatchSentence);        

        let value = highestMatch[currentIndex];
        let message;
        let iconUrl;

        switch (true) {
          case (value < 10):
            message = translations[selectedLanguage].DontGiveUp; 
            iconUrl = 'Icons/dontgiveup.svg'; 
            break;
          case (value >= 10 && value < 20):
            message = translations[selectedLanguage].YouCanDoIt; 
            iconUrl = 'Icons/youcandoit.svg';   
            break;
          case (value >= 20 && value < 30):
            message = translations[selectedLanguage].KeepPracticing;
            iconUrl = 'Icons/keeppracticing.svg';
            break;
          case (value >= 30 && value < 40):
            message = translations[selectedLanguage].KeepGoing;
            iconUrl = 'Icons/keepgoing.svg';  
            break;
          case (value >= 40 && value < 50):
            message = translations[selectedLanguage].NotBad
            iconUrl = 'Icons/notbad.svg'; 
            break;
          case (value >= 50 && value < 60):
            message = translations[selectedLanguage].GoodJob;
            iconUrl = 'Icons/goodjob.svg';
            break;
          case (value >= 60 && value < 70):
            message = translations[selectedLanguage].Bravo;
            iconUrl = 'Icons/bravo.svg';
            break;
          case (value >= 70 && value < 75):
            message = translations[selectedLanguage].Super;
            iconUrl = 'Icons/super.svg';
            break;
          case (value >= 75 && value < 80):
            message = translations[selectedLanguage].Excellent;
            iconUrl = 'Icons/excellent.svg';
            break;
          case (value >= 80 && value < 90):
            message = translations[selectedLanguage].Wow;
            iconUrl = 'Icons/wow.svg';
            break;
          case (value >= 90):
            message = translations[selectedLanguage].Pro;
            iconUrl = 'Icons/pro.svg';
            break;
          default:
            message = translations[selectedLanguage].OutOfRange;         
        }
        if (currentIndex < conversations[speakConversationIndex].pairs.length - 1) {
          showSweetAlert(message, 'practice-message', iconUrl);
        } else if (currentIndex === conversations[speakConversationIndex].pairs.length - 1){
          setTranscription('');
          showLastSweetAlert(message, 'practice-message', iconUrl, navigate, '/pronunciation-report');
        }

        if (currentIndex === conversations[speakConversationIndex].pairs.length - 1 ||
          (endWithAI && currentIndex === conversations[speakConversationIndex].pairs.length - 2)
        ) {
          updateUserReports(selectedLanguage, 1);     // MANUAL INPUT
        }

        nextConversationPair();
      }
  }, [highestMatch]);  
  
  /********** POPUP INSTRUCTION **********/
  const situationRef = useRef(null);
  const changeRef = useRef(null);  
  const transcriptionRef = useRef(null); 
  const optionRef = useRef(null);
  const microphoneRef = useRef(null);

  const steps = [
    { ref: situationRef, label: translations[selectedLanguage].SituationLabel, text: translations[selectedLanguage].collapseSituationDescription },
    { ref: changeRef, label: translations[selectedLanguage].changeLabel, text: translations[selectedLanguage].changeSituation},
    { ref: transcriptionRef, label: translations[selectedLanguage].TranscriptionLabel, text: translations[selectedLanguage].Seetranscription },
    { ref: optionRef, label: translations[selectedLanguage].Options, text: translations[selectedLanguage].possibleAnswers },
    { ref: microphoneRef, label: translations[selectedLanguage].MicrophoneLabel, text: translations[selectedLanguage].Recordyouranswers },    
  ];

  /********* dynamically adjust the viewport height on mobile *********/
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initially
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 
  
  /********** INTERFACE **********/ 
  return (
    <div >
    {conversations.length > 0 ? (
    <div className="page-container">
    <div className="top-row">
        <button onClick={() => goBack()} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].PracticeSpeaking}</h3>
        <BurgerMenuComponent />        
      </div> 

    <div className="practice-container">
      <div className="conversation-dialog-container">
      {visiblePairs.map((pair, index) => (        
      <div key={index} className="conversation-dialog">
        { (!startWithUser || (startWithUser && index > 0)) &&
          <><span  className="conversation-label" style={{marginRight:'auto'}}>{translations[selectedLanguage].SpeakAI}</span>
        <div className="application-container">
          <div className="application-answer">            
            <div >              
              <button  style={{marginLeft: '0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(pair.application.fr, level, englishTopic))}>
               <img src={isPlaying && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) ? "Icons/pause-practice-application-active.svg" : "Icons/listen-summary.svg"} alt={isPlaying ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
              </button>
              <button className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(pair.application.fr, level, englishTopic))}>
                <img src={isPlayingSlower && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) ? "Icons/pause-practice-application-active.svg" : "Icons/turtle-summary.svg"} alt={isPlaying ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} /> 
              </button>
              <button className="translation-button-practice" onClick={() => translateApplication(pair.application.translation, index)}>
                 <img src={translationIndex === index && translationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
              </button>  
              {index > 0 && <button ref={transcriptionRef} className="transcription-button-practice-AI" onClick={() => transcribeApplication(pair.application.fr, index)}>
                <img  src={transcriptionIndex === index && transcriptionText !== '' ? "Icons/transcription-practice-application-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
              </button>}
            </div>               
            {isPlayingSlower && currentAudioFile === frToAudio(pair.application.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                
                <div>
                  {((transcriptionIndex === index && transcriptionText !== '') ||  (translationIndex === index && translationText !== '')) && <hr/>}                  
                  {index === 0 && (              
                    <div style={{color:'#ffffff'}}>{firstAIText}</div>
                  )}
                  {index > 0 && transcriptionIndex === index && (              
                    <div style={{color:'#ffffff'}}>{transcriptionText}</div>
                  )}  
                  {translationIndex === index && (
                    <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} style={{color:'#E07F9C'}}>{translationText}</div>
                  )} 
                </div>    
          </div>  
        </div></>}
        
        { (!endWithAI || (endWithAI && index < (conversations[speakConversationIndex].pairs.length - 1))) &&
        <><span className="conversation-label" style={{marginLeft:'auto'}}>{translations[selectedLanguage].UserSpeak}</span>
        <div className="user-container">   
        {(selectedLanguage === 'ar' || selectedLanguage === 'fa') ?
          <div className="user-answers">
            {/* {showHint &&
            <div dir='rtl' style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint} <hr /></div>           
            } */}
            {pair.suggestResponses.length === 1 ? 
              <>
                <div className="user-answer">
                <button style={{marginLeft:'0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(pair.suggestResponses[0].fr, level, englishTopic))}>
                  <img src={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                </button> 
                <button className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(pair.suggestResponses[0].fr, level, englishTopic))}>
                  <img src={isPlayingSlower && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                </button>                 
                <button style={{marginRight:'10px'}} className="translation-button-practice" onClick={() => translateSuggestAnswer(pair.suggestResponses[0].translation, index, 0)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === 0 && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button> 
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                                    
                <div style={{color:'#062E5F'}}>{pair.suggestResponses[0].fr}</div>         
                {userTranslationIndex === index && userTranslationIdx === 0 && (
                  <div dir='rtl' style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}
              </>
            :
            (pair.suggestResponses.map((response, idx) => (
              <div key={idx} >
                { idx < 3 && (<>
                <div className="user-answer">
                <button style={{marginLeft:'0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                </button> 
                <button className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                </button>                 
                <button style={{marginRight:'10px'}} className="translation-button-practice" onClick={() => translateSuggestAnswer(response.translation, index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>
                {/* <button style={{marginRight:'20px'}} className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>    */}
                <div dir='rtl' ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>  
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                                   
                <div style={{color:'#062E5F'}}>{response.fr}</div>  
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div dir='rtl' style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )} 
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            )))  }         
          </div>
        :
<div className="user-answers">
            {/* {showHint &&
              <div style={{color:'#E07F9C'}}><strong>{translations[selectedLanguage].HINT}: </strong>{pair.hint} <hr /></div>           
            } */}

            {pair.suggestResponses.length === 1 ? 
              <>             
                <div className="user-answer">                   
                  <button style={{marginLeft: '0px'}} className="speaker-button-practice" onClick={() => playPause(frToAudio(pair.suggestResponses[0].fr, level, englishTopic))}>
                    <img src={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                  </button> 
                  <button className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(pair.suggestResponses[0].fr, level, englishTopic))}>
                    <img src={isPlayingSlower && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                  </button>                 
                  <button className="translation-button-practice" onClick={() => translateSuggestAnswer(pair.suggestResponses[0].translation, index, 0)}>
                    <img src={userTranslationIndex === index && userTranslationIdx === 0 && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                  </button>
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(pair.suggestResponses[0].fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                  
                <div style={{color:'#062E5F'}}>{pair.suggestResponses[0].fr}</div>
                {userTranslationIndex === index && userTranslationIdx === 0 && (
                  <div style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}                                                      
              </>
            :
            (pair.suggestResponses.map((response, idx) => (
              <div key={idx} >              
                { idx < 3 && (<>
                <div className="user-answer">
                 <div  ref={optionRef} ><b>{translations[selectedLanguage].Option}{` ${idx + 1}: `}</b></div>
                <button className="speaker-button-practice" onClick={() => playPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/listen-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Listen} />
                </button> 
                <button className="slowdown-button-practice" onClick={() => slowerPause(frToAudio(response.fr, level, englishTopic))}>
                  <img src={isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? "Icons/pause-summary.svg" : "Icons/turtle-summary.svg"} alt={isPlaying && currentAudioFile === frToAudio(response.fr, level, englishTopic) ? translations[selectedLanguage].Pause : translations[selectedLanguage].Slow} />
                </button>                 
                <button className="translation-button-practice" onClick={() => translateSuggestAnswer(response.translation, index, idx)}>
                  <img src={userTranslationIndex === index && userTranslationIdx === idx && userTranslationText !== '' ? "Icons/translation-practice-active.svg" : "Icons/translation-practice.svg"} alt={translations[selectedLanguage].Translation} />
                </button>
                {/* <button className="transcription-button-practice" onClick={() => toggleShowAnswer(index, idx)}>
                  <img  src={answersIndex === index && answersIdx === idx ? "Icons/transcription-practice-user-active.svg" : "Icons/transcription-practice.svg"} alt={translations[selectedLanguage].Transcription} />
                </button>    */}
                </div>
                {isPlayingSlower && currentAudioFile === frToAudio(response.fr, level, englishTopic) && <div style={{minWidth:'300px'}}><PlaybackRateControl /> </div>}                                    
                {/* {showAllTranscription ?  */}
                <div style={{color:'#062E5F'}}>{response.fr}</div>                
                {/* : 
                  answersIndex === index && answersIdx === idx && (
                    <div style={{color:'#062E5F'}}>{response.fr}</div>                  
                  ) 
                }               */}
                {userTranslationIndex === index && userTranslationIdx === idx && (
                  <div style={{color:'#E07F9C'}}>{userTranslationText}</div>
                )}
                {idx < Math.min(2, pair.suggestResponses.length - 1) && <hr />}
                </>)}                   
              </div>
            ))) }           
          </div>
        }                   
        </div></>}  
        {(endWithAI && index === (conversations[speakConversationIndex].pairs.length - 1)) && <div className="user-container">
            <button className="arrow-practice"  onClick={() => navigate('/pronunciation-report')}>
            <img src={"/Icons/arrow-right-practice.svg"} alt={translations[selectedLanguage].next} className="arrow-right" />
            </button>  
        </div>  }        
      </div>
      ))}
      <div ref={messagesEndRef} />
      </div></div>
      
      <div className="microphone-practice" style={{marginTop:'5px'}} > 
        {recording ? (
            <button className="recording-animation-practice" style={{scale:'0.8'}} onClick = {() =>  stopRecording()}>
            {selectedLanguage === 'en' ? <img src="/Icons/stop-practiceEN.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'es' ? <img src="/Icons/stop-practiceES.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'pt_br' ? <img src="/Icons/stop-practicePTBR.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'ar' ? <img src="/Icons/stop-practiceAR.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'fa' ? <img src="/Icons/stop-practiceFA.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'zh' ? <img src="/Icons/stop-practiceZH.svg" alt={translations[selectedLanguage].Stop} /> 
            : selectedLanguage === 'vi' ? <img src="/Icons/stop-practiceVI.svg" alt={translations[selectedLanguage].Stop} /> 
            : <img src="/Icons/stop-practice.svg" alt={translations[selectedLanguage].Stop} /> }     
            </button>
        )
        :
          <button ref={microphoneRef} className="microphone-button-practice" onClick = {() =>  handleStartRecording()}>
          <img src="Icons/microphone-practice.svg" alt={translations[selectedLanguage].Microphone} />
          </button>
        }
        <br/><br/>
      </div>
        <BottomMenuComponent steps={steps}/>
        </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
};

export default PracticeSpeaking;