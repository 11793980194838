import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import { useUserAnswers } from '../Practice/UserAnswers';
import DataContext from './DataContext';

const BurgerMenuComponent = () => {
  let navigate = useNavigate();
  const [isBurgerMenuClicked, setIsBurgerMenuClicked] = useState(false);
  const { selectedLanguage } = useLanguage();
  const { setInstructionForConversation, setInstructionForLesson, setConversations, sentencesForLesson, setSentencesForLesson, lessons, topics, fetchTopics, fetchLessonGroups, allLessons, fetchAllLessons, allTopics, fetchAllTopics, sentencesForStudy, setSentencesForStudy } = useContext(DataContext);
  const {updateTopic, updateLesson, updateLessonIndex, updateLessonEn, updateEnglishTopic, updateLevel } = useUserAnswers();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [filteredTopicResults, setFilteredTopicResults] = useState([]);  
  const [topicClick, setTopicClick] = useState(false);
  const [lessonClick, setLessonClick] = useState(false);

  useEffect(() => {
    fetchAllTopics(selectedLanguage);
    fetchAllLessons(selectedLanguage);
  }, []);

  const handleBurgerMenuClose = () => {
    setIsBurgerMenuClicked(false);
  };

  const goToHome = () => {
    setIsBurgerMenuClicked(false);
    navigate('/');
  };
  const goToMyVocab = () => {
    setIsBurgerMenuClicked(false);
    navigate('/my-vocabularies');
  };
  const goToMyReports = () => {
    setIsBurgerMenuClicked(false);
    navigate('/my-reports');
  };
  const goToGrammar = () => {
    setIsBurgerMenuClicked(false);
    navigate('/grammar');
  };
  const goToPronunciation= () => {
    setIsBurgerMenuClicked(false);
    navigate('/pronunciation');
  };
      
  const handleSearchChange = (e) => {   
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  
    if (term) {
      const results = new Set(); // Use Set to avoid duplicates
      allLessons.forEach((lesson) => {
        if (lesson.lesson_name.toLowerCase().includes(term)) {
          let result = lesson;
          result.type = 'lesson';
          results.add(result);
        }
      }); 
      allTopics.forEach((topic) => {
        if (topic.topic_name.toLowerCase().includes(term)) {
          let result = topic;
          result.type = 'conversation';
          results.add(result);
        }
      });         
      setFilteredResults(Array.from(results));
    } else {
      setFilteredResults([]);
      setFilteredTopicResults([]);
    }
  };  
  
  const handleResultClick = async (result) => {   
    // let levelFound = await fetchSearchLevel(getTopicName(result, 'en'));    
    if (result.level_id > 0) {
      updateLevel(result.level_id); 
      fetchTopics(result.level_id, selectedLanguage);
      fetchLessonGroups(result.level_id, selectedLanguage);

      if(result.type === 'lesson'){
        setInstructionForLesson(''); 
        setSentencesForLesson([]);   
        updateLessonIndex(result.number - 1);   // NOTE: number column must be consecutive
        updateLesson(result.lesson_name);           
        updateLessonEn(result.lesson_name_en);        
        updateTopic('');
        updateEnglishTopic('');        
        setLessonClick(true);        
      } else if(result.type === 'conversation'){
        setInstructionForConversation('');  
        setSentencesForStudy([]);
        setConversations([]);                 
        updateTopic(result.topic_name);
        updateEnglishTopic(result.topic_name_en);
        updateLesson('');     
        updateLessonEn(''); 
        setTopicClick(true);
      }
       
      setSearchTerm('');
      setIsBurgerMenuClicked(false);        
     }       
  };

  useEffect(() => {    
    if (topicClick){
      setTopicClick(false);
      navigate('/conversation-instruction');
    }
    if (lessonClick){
      setLessonClick(false);
      navigate('/lesson-instruction');
    }
  }, [topicClick, lessonClick]);

  /********** to close searchbar **********/  
  const searchbarRef = useRef(null);
  const handleClickOutside = (event) => {
    if (searchbarRef.current && !searchbarRef.current.contains(event.target)) {
      setSearchTerm('');
      setFilteredResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <button className="burger-menu-icon" onClick={() => setIsBurgerMenuClicked(true)}>
        <img src={`/Icons/burgermenu.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].Menu} />
      </button>
      {isBurgerMenuClicked && (
        <div className="popup-burgermenu">
          <button className="x-button" onClick={() => handleBurgerMenuClose()}><b>X</b></button>

          <div className="search-bar" ref={searchbarRef}>
          <input type="text" value={searchTerm} onChange={handleSearchChange} 
              placeholder={translations[selectedLanguage].SearchATopic} />
          <button className="search-icon-button">
              <img src={`/Icons/search.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].Search} />
          </button>
          </div>

          {(filteredResults.length > 0 || filteredTopicResults.length > 0) && (
              <div className="search-results" ref={searchbarRef}>
              {filteredResults.map((result, index) => (
                  <button
                  key={index}
                  className="search-result-button"
                  onClick={() => handleResultClick(result)}
              >
                  {result.type === "conversation" ? result.topic_name : result.lesson_name}
              </button>
              ))}                
              </div>
          )}
        
          <div className="menu-buttons">
            <button className="menu-button" onClick={() => goToHome()}>{translations[selectedLanguage].Home}</button>
            <button className="menu-button" onClick={() => goToGrammar()}>{translations[selectedLanguage].Grammar}</button>
            <button className="menu-button" onClick={() => goToPronunciation()}>{translations[selectedLanguage].Pronunciation}</button>  
            <button className="menu-button" onClick={() => goToMyVocab()}>{translations[selectedLanguage].MyVocab}</button> 
            <button className="menu-button" onClick={() => goToMyReports()}>{translations[selectedLanguage].MyReports}</button>            
            {/* <button className="menu-button" onClick={() => navigate('/profile')}>{translations[selectedLanguage].MyProfile}</button>*/}
                         
          </div>

        </div>
      )}
    </>
  );
};

export default BurgerMenuComponent;