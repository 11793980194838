import React  from 'react';
import { translations } from './Utilities';
import { useLanguage } from './LanguageProvider';
import { useNavigate } from 'react-router-dom';
// import FlagComponent from './FlagComponent';
import HelpComponent from './HelpComponent';
import { useRecordUser } from './RecordUser';
import { useUserAnswers } from '../Practice/UserAnswers';
import './Reusables.css'; 

const BottomMenuComponent = React.memo(({ steps }) => {
  const { selectedLanguage } = useLanguage();
  const navigate = useNavigate();
  const {setTranscription} = useRecordUser();  
  const {topic} = useUserAnswers();

  const goToStudy = () => {  
    setTranscription('');    
    navigate('/speaking-sentences');
  };

  const goToSummary = () => {      
    navigate('/summary-page');
  };

  const goToPractice = () => { 
    setTranscription(''); 
    navigate('/practice');           
  };
  const goToLessonInstruction = () => { 
    setTranscription(''); 
    navigate('/lesson-instruction');          
  };

  const goToConvoInstruction = () => { 
    setTranscription(''); 
    navigate('/conversation-instruction');          
  };

  return (    
    (topic !== '' ? 
      <div className="bottom-menu">
      <button className="menu-icon-instruction" data-title={translations[selectedLanguage].lessonInstruction} onClick={() => goToConvoInstruction()}>
        <img style={{width:'25px', height:'25px'}}  src={"/Icons/instruction.svg"} alt={translations[selectedLanguage].lessonInstruction} />
      </button> 
      <button className="menu-icon-study" data-title={translations[selectedLanguage].study} onClick={() => goToStudy()}>
        <img style={{width:'32px', height:'30px'}}  src="/Icons/study-bottome-menu.svg" alt={translations[selectedLanguage].study} />
      </button>
      <button className="menu-icon-summary" data-title={translations[selectedLanguage].summary} onClick={() => goToSummary()}>
        <img style={{width:'26px', height:'26px'}}  src="/Icons/summary-bottome-menu.svg" alt={translations[selectedLanguage].summary} />
      </button>      
      <button className="menu-icon-practice" data-title={translations[selectedLanguage].Practice} onClick={() => goToPractice()}>
        <img style={{width:'25px', height:'25px'}}  src={ "/Icons/practice-bottom-menu.svg"} alt={translations[selectedLanguage].Practice} />
      </button>
      <HelpComponent steps={steps} /> 
      </div>  
      :
        <div className="bottom-menu">
        <button className="menu-icon-instruction" data-title={translations[selectedLanguage].lessonInstruction} onClick={() => goToLessonInstruction()}>
          <img style={{width:'25px', height:'25px'}}  src={"/Icons/instruction.svg"} alt={translations[selectedLanguage].lessonInstruction} />
        </button> 
        <button className="menu-icon-study" data-title={translations[selectedLanguage].study} onClick={() => goToStudy()}>
          <img style={{width:'32px', height:'30px'}}  src="/Icons/study-bottome-menu.svg" alt={translations[selectedLanguage].study} />
        </button>
        <button className="menu-icon-summary" data-title={translations[selectedLanguage].summary} onClick={() => goToSummary()}>
          <img style={{width:'26px', height:'26px'}}  src="/Icons/summary-bottome-menu.svg" alt={translations[selectedLanguage].summary} />
        </button>      
            
        <HelpComponent steps={steps} />        
      </div>
    )
  );
});

export default BottomMenuComponent;
