import React, { useState, useEffect, useRef } from 'react';
import { translations, getPopupPosition } from './Utilities';
import { useLanguage } from './LanguageProvider';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

const HelpComponent = ({ steps, direction }) => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  const { selectedLanguage } = useLanguage();
  const overlayRef = useRef(null);  

  useEffect(() => {
    // Define the list of pages where the help function is needed
    const helpPages = [
      '/speaking-sentences',
      '/summary-page',
      '/practice',
      '/practice-speaking',
      '/practice-writing',
    ];

    // Check if the current page is in the list
    if (helpPages.includes(location.pathname)) {
      const helpKey = `help-shown-${location.pathname}`; // Unique key for the page
      const helpShown = localStorage.getItem(helpKey); // Check localStorage

      if (!helpShown) {
        // Automatically show help for first-time users
        handleHelpClick();
        localStorage.setItem(helpKey, 'true'); // Mark as shown
      }
    }
  }, [location.pathname]);

  const handleHelpClick = () => {
    if (steps && steps.length > 0) {
      setCurrentStep(0);
      setShowPopup(true);
    }
  };

  const handleCloseClick = () => {
    setShowPopup(false);
  };

  const handleNextClick = () => {
    if (currentStep < steps.length - 1) {
      const currentRef = steps[currentStep]?.ref?.current;
      if (currentRef) {
          resetHighlight(currentRef);
      }
      setCurrentStep(currentStep + 1);
    } else {
      setShowPopup(false);
    }
  };

  const handlePrevClick = () => {
    if (currentStep > 0) {
      const currentRef = steps[currentStep]?.ref?.current;
      if (currentRef) {
          resetHighlight(currentRef);
      }
      setCurrentStep(currentStep - 1);
    } else {
      setShowPopup(false);
    }
  };

  const highlightElement = (element) => {
    if (element) {
      element.classList.add('highlighted-element');
      overlayRef.current.style.display = 'block';
    }
  };

  const resetHighlight = (element) => {
    if (element) element.classList.remove('highlighted-element');
    overlayRef.current.style.display = 'none';
  };

  useEffect(() => {
    if (showPopup && steps.length > 0) {
      const currentRef = steps[currentStep]?.ref?.current;
      if (currentRef) {
          highlightElement(currentRef);
          const topPosition = currentRef.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: topPosition - 100, behavior: 'smooth' });
      }
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      };

      const handleResize = () => {
        setShowPopup(false);
      };

      document.addEventListener('mousedown', handleClickOutside);
      window.addEventListener('resize', handleResize);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        window.removeEventListener('resize', handleResize);
      };
    } else {
      const currentRef = steps[currentStep]?.ref?.current;
      if (currentRef) {
          resetHighlight(currentRef);
      }
    }
  }, [showPopup, currentStep, steps]);

  // render the popup in a more global context using a portal
  const popupContent = (showPopup && steps.length > 0 && (
    <div ref={popupRef} className="popup-container" style={getPopupPosition(steps[currentStep].ref, direction)}>
      <div className="popup-container-layout">
      <div style={{display: 'flex'}}>          
      <span style={{maxWidth: '87%'}}><b>{steps[currentStep]?.label}</b>      
          {steps[currentStep]?.text ? (
            steps[currentStep].text.split('<br/>').map((line, index, array) => (
              <React.Fragment key={index}>
                {line}
                {index < array.length - 1 && <br />}
              </React.Fragment>
            ))
            ) : (
              // Fallback content if text is undefined
              <React.Fragment>No instructions available</React.Fragment>
            )}</span>
      <button className="popup-close-button" onClick={() => handleCloseClick()}><b>X</b></button>
      </div>
      <div className="popup-button-group">
        <button onClick={() => handlePrevClick()} id="prevButton" className="popup-button">&lt;</button>
        <button onClick={() => handleNextClick()} id="nextButton" className="popup-button">&gt;</button>
      </div>
      </div>
    </div>
  ));

  return (
    <div>
      <button className="help-icon" data-title={translations[selectedLanguage].Help} onClick={() => handleHelpClick()}>
        <img style={{width:'25px', height:'25px'}}  src="/Icons/help.svg" alt={translations[selectedLanguage].Help} />
      </button>
      {ReactDOM.createPortal(popupContent, document.body)}
      <div ref={overlayRef} className="overlay" style={{ display: 'none' }}></div>
    </div>
  );
};

// const getPopupPosition = (ref, direction) => {
//   if (!ref || !ref.current) {
//     return { top: '0px', left: '0px' }; // Default position if ref is undefined
//   } else {
//     const rect = ref.current.getBoundingClientRect(); 
//     let top = rect.bottom  + window.scrollY ;   
//     if(direction === 'top'){
//       top = rect.top ;
//     }     
//     let left = rect.left + 5;

//     // Adjust if popup overflows right
//     if (left + 200 > window.innerWidth) {
//       left = window.innerWidth - 220;
//     }

//     // Adjust if popup overflows bottom
//     if (top + 100 > window.innerHeight) {
//       top = top - 150;
//     }

//     // Ensure the popup doesn't overflow out of the left side
//     if (left < 10) {
//       left = 10;
//     }

//     // Ensure the popup doesn't overflow out of the top side
//     if (top < 10) {
//       top = 10;
//     }
    
//     return {
//       top: `${top}px`,
//       left: `${left}px`,
//     };
//   } 
// };

export default HelpComponent;