
import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

  const PlayAudio = createContext({
    play: () => {},
    slowerPause: () => {},
    stopAudio: () => {},
    playPause:() => {},
    isPlaying: false,
    isPlayingSlower: false,
    currentAudioFile: '',
    playbackRate: 1,
    setPlaybackRate: () => {},
    playPauseIPA:() => {},
  });
  
  export const usePlayAudio = () => useContext(PlayAudio);
  
  export const PlayAudioProvider = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayingSlower, setIsPlayingSlower] = useState(false);
    const audioRef = useRef(new Audio());    
    const [currentAudioFile, setCurrentAudioFile] = useState('');
    const [playbackRate, setPlaybackRate] = useState(1);

    // Update the audio playback rate whenever playbackRate changes
    useEffect(() => {
        audioRef.current.playbackRate = playbackRate;
    }, [playbackRate]);

    // Cleanup to stop the audio when the component unmounts or audio needs to be stopped
    useEffect(() => {
        const audio = audioRef.current;
        return () => {
            audio.pause();
            audio.src = '';  // Clear the source
            audio.onended = null;
        };
    }, []);

    const stopAudio = () => {
        const audio = audioRef.current;
        audio.pause();
        audio.src = ''; // Clear the source to ensure media release
        audio.onended = null;
        setIsPlaying(false);
        setIsPlayingSlower(false);
        setCurrentAudioFile('');
    }

    const play = (audioFile) => {
        const audio = audioRef.current;

        if (currentAudioFile === '' || currentAudioFile !== audioFile){
            audio.src = audioFile;          
            audio.load();                   
            setCurrentAudioFile(audioFile);
            audio.playbackRate = 1; 
        }        
        audio.onended = () => {
            setCurrentAudioFile('');
        };         
        
        audio.oncanplay = () => {      
            audio.play().catch(error => console.error("Error playing the audio", error));
        };
        audio.onerror = (event) => {
            console.error("Error occurred when trying to load the audio.", event);
        };
    }

    const playPause = (audioFile) => {
        const audio = audioRef.current;

        // Setup event listener for when audio ends
        audio.onended = () => {
            setIsPlaying(false);
            setCurrentAudioFile('');
        };

        if (!isPlaying || (isPlaying && currentAudioFile !== audioFile)){
            if (currentAudioFile === '' || currentAudioFile !== audioFile){
                audio.src = audioFile;          
                audio.load();                   
                setCurrentAudioFile(audioFile);
            }

            audio.playbackRate = 1; 
            setIsPlayingSlower(false);  
            setIsPlaying(true); 

            audio.play().then(() => {
                console.log("Audio started playing");
            }).catch(error => {
                console.error("Error playing the audio", error);
                setIsPlaying(false);  // Revert state if play failed
            });
        } else {
            audio.pause();
            setIsPlaying(false);
        }
    }

    const slowerPause = (audioFile) => {
        const audio = audioRef.current;

        // Setup event listener for when audio ends
        audio.onended = () => {
            setIsPlayingSlower(false);
            setCurrentAudioFile('');
        };

        if (!isPlayingSlower || (isPlayingSlower && currentAudioFile !== audioFile)){
            if (currentAudioFile === '' || currentAudioFile !== audioFile){
                audio.src = audioFile;          
                audio.load();                   
                setCurrentAudioFile(audioFile);
            }

            audio.playbackRate = playbackRate;
            if(playbackRate === 1){
                setPlaybackRate(0.6);
                audio.playbackRate = 0.6; 
            } 
            setIsPlaying(false);   
            setIsPlayingSlower(true);        

            audio.play().then(() => {
                console.log("Audio started playing");
            }).catch(error => {
                console.error("Error playing the audio", error);
                setIsPlayingSlower(false);  // Revert state if play failed
            });
        } else {
            audio.pause();
             setIsPlayingSlower(false);
        }
    } 

    const playPauseIPA = (audioFile) => {
        const audio = audioRef.current;

        // Setup event listener for when audio ends
        audio.onended = () => {            
            setCurrentAudioFile('');
        };
        
        if (currentAudioFile === '' || currentAudioFile !== audioFile){
            audio.src = audioFile;          
            audio.load();                   
            setCurrentAudioFile(audioFile);
        }
               
        audio.playbackRate = 1;                

        audio.play().then(() => {
            console.log("Audio started playing");
        }).catch(error => {
            console.error("Error playing the audio", error);            
        });
        
    }
    
    const handlePlaybackRateChange = (newRate) => {
        setPlaybackRate(newRate);
        if (isPlayingSlower && currentAudioFile) {
          const audio = audioRef.current;
          audio.playbackRate = newRate;
        }
      };

    return (
        <PlayAudio.Provider value={{play, stopAudio, slowerPause, playPause, playPauseIPA, isPlaying, isPlayingSlower, currentAudioFile, playbackRate, setPlaybackRate : handlePlaybackRateChange }}>
            {children}
        </PlayAudio.Provider>
    );
  };
  
  export const PlaybackRateControl = () => {
    const { playbackRate, setPlaybackRate } = usePlayAudio();
     
    const handlePlaybackRateChange = (e) => {
      const newRate = parseFloat(e.target.value);
      setPlaybackRate(newRate);
    };

    const decreaseRate = () => {
        const newRate = Math.max(0.4, playbackRate - 0.1);
        setPlaybackRate(newRate);
    }

    const increaseRate = () => {
        const newRate = Math.min(playbackRate + 0.1, 0.8);
        setPlaybackRate(newRate);
    }
  
    return (
      <div className="playback-rate-control">
        <button className="decrease-rate" onClick={() => decreaseRate()}>-&nbsp;&nbsp;</button>
        <input
        type="range"
        min="0.4"
        max="0.8"
        step="0.1"
        value={playbackRate}
        onChange={handlePlaybackRateChange}
        />
        <button className="increase-rate" onClick={() => increaseRate()}>&nbsp;&nbsp;+</button>
      </div>
    );
  };