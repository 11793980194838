import React, {useContext, useRef, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { translations, getInstructionVideo } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import { useUserAnswers  } from '../Practice/UserAnswers';
import DataContext from '../Reusables/DataContext';
import { useRecordUser } from '../Reusables/RecordUser';
import './Home.css'; 

const Home = () => {
  let navigate = useNavigate();
  const { selectedLanguage, updateSelectedLanguage } = useLanguage(); 
  const {level, updateLevel} = useUserAnswers();
  const { levels, fetchLevels, lessons, topics, setTopics, setLessons } = useContext(DataContext);
  const [clickStart, setClickStart] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const { stopAudioRecordingRedbutton} = useRecordUser();  
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('instructionShown')) {      
      setShowHelp(true);    // Automatically show help for first-time users   
    }
    fetchLevels(selectedLanguage);  
    return () => {
      stopAudioRecordingRedbutton();       
    };
  }, []);  

  const handleLevelChange = (e) => {
    updateLevel(e.target.value);
  };
  
  const languages = [
    { code: 'en', name: 'English'}, 
    { code: 'es', name: 'Español' },   // Spanish
    { code: 'pt_br', name: 'Português' }, // Portuguese
    { code: 'ar', name: 'العربية' },   // Arabic
    { code: 'fa', name: 'فارسی' },     // Persian (Farsi)
    { code: 'zh', name: '中文' },       // Simplified Chinese
    { code: 'vi', name: 'Tiếng Việt' } // Vietnamese
  ];

  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  
  const toggleLanguageMenu = () => {
    setShowLanguageMenu(!showLanguageMenu);
  };
  
  const handleLanguageChange = (language) => {
    updateSelectedLanguage(language);
    console.log("Language selected:", language);
    fetchLevels(language);   
    setShowLanguageMenu(!showLanguageMenu);
  };

  useEffect(() => {    
    if (clickStart && topics.length === 0 && lessons.length === 0){ 
      if (!localStorage.getItem('instructionShown')) {
        // Automatically show help for first-time users  
        setShowVideo(true);
      } else {
        navigate('/topic-selection');
      }  
    }
  }, [clickStart]);

  const handleStart = () => {
    if (level === 0) {
      updateLevel(1);
    }
    setTopics([]);
    setLessons([]);
    setClickStart(true); 
  };

  /********** instruction video **********/  
  const handleVideoInstructionClick = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    if (!localStorage.getItem('instructionShown')) {          
      localStorage.setItem('instructionShown', 'true'); 
      navigate('/topic-selection');
    }  
  };

  /********** language menu **********/  
  const languageMenuRef = useRef(null);
  const overlayRef = useRef(null);
  
  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutsideLanguageMenu = (event) => {
      if (languageMenuRef.current && !languageMenuRef.current.contains(event.target)) {
        setShowLanguageMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideLanguageMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideLanguageMenu);
    };
  }, []);

  useEffect(() => {
    if (showHelp) {
      overlayRef.current.style.display = 'block';
      languageMenuRef.current.classList.add('highlighted-element');

      const handleClickOutsideLanguageButton = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setShowHelp(false);
        }
      };
  
      if (showHelp) {
          document.addEventListener('mousedown', handleClickOutsideLanguageButton);
      }
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutsideLanguageButton);
      };
    } else {
        overlayRef.current.style.display = 'none'; 
        languageMenuRef.current.classList.remove('highlighted-element'); 
    }    
  }, [showHelp]);

  return (
    <div className="start-container">
      <div className="top-right-container">
      <div className="language-container"  ref={languageMenuRef}>
        <button className="language-button" onClick={() => toggleLanguageMenu()}>
          {selectedLanguage.toUpperCase()} <span className="dropdown-icon">▼</span>
        </button>
        {showLanguageMenu && (
          <div className="language-menu">
            {languages.map((language) => (
              <div
                key={language.code}
                className="language-option"
                onClick={() => handleLanguageChange(language.code)}
              >
                {language.name}
              </div>
            ))}
          </div>
        )}
      </div>
      {/* <button className="login-button">
        <img src="/Icons/login.svg" alt="Login" className="login-icon" />
      </button> */}
      </div>    
      
      <div className="logo">           
        <img src="logo-header-center.png" alt={translations[selectedLanguage].Logo} />
      </div>

      <div className="level-selector">
        <select value={level} onChange={handleLevelChange}>
          {levels.map((level) => (
            <option key={level.level_id} value={level.level_id}>
              {level.level_name}
            </option>
          ))}
        </select>
      </div>

      <button className="instruction-link" onClick={() => handleVideoInstructionClick()}>{translations[selectedLanguage].InstructionVideo}</button> 
      {showVideo && (
        <div className="video-popup-overlay">
          <button className="video-close-button" onClick={() => handleCloseVideo()}>X</button>
          <video controls className="responsive-video">
            <source src={getInstructionVideo(selectedLanguage)} type="video/mp4" />
            {translations[selectedLanguage].Doesnotsupportvideo}
          </video>
        </div>
      )}

      <button className="start-button" onClick={() => handleStart()}>
        <b>{translations[selectedLanguage].start} </b>
      </button>

      {/* to autoshow language for first user */}
      <div ref={overlayRef} className="overlay" style={{ display: 'none' }}></div>
            
    </div>
  );
};

export default Home;
