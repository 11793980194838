
import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import BottomMenuComponent from '../Reusables/BottomMenuComponent';
import './CompletionPage.css'; 

const CompletionPage = () => {

  useEffect(() => {
    const audio = new Audio('/Audio/success.wav');
    audio.play().catch((error) => console.error('Audio playback failed:', error));
  }, []);

  let navigate = useNavigate();
  const { level, topic, points} = useUserAnswers();
  const { selectedLanguage } = useLanguage();

  return (
    <div className="page-container">
      <div className="top-row">
        <button onClick={() => navigate(-1)} className="back-button"> <img src="/Icons/arrow-left.svg" alt={translations[selectedLanguage].back} />  </button>
        <h3>{translations[selectedLanguage].BravoYouFinished}</h3>
        <BurgerMenuComponent />  
      </div>
      <div dir={(selectedLanguage === 'ar' || selectedLanguage === 'fa') ? 'rtl' : 'ltr'} className="completion-page-content">
        <ul className="completion-details">
          <li><b>{translations[selectedLanguage].Level}:</b> {level}</li>             
          <li><b>{translations[selectedLanguage].Conversation}:</b> {topic}</li>
          <li><b>{translations[selectedLanguage].YourPoints}</b> {points}</li>
        </ul>
        

        <div className="practice-options">
          <button style={{margin:'50px auto 0px auto'}} className="practice-again-button" onClick={() => navigate('/practice')}>
            {translations[selectedLanguage].PracticeAgain}
            <img style={{width:'25px', height:'25px', marginLeft:'auto'}} src="Icons/refresh.svg" alt="" /></button>                  
          <button className="another-topic-button" onClick={() => navigate('/topic-selection', { state: { fromCompletionPage : true } })}>
            {translations[selectedLanguage].ChooseAnotherConversation}&ensp;
            <img style={{width:'25px', height:'25px', marginLeft:'auto'}} src="Icons/another.svg" alt="" /></button>
          <button className="revise-button" onClick={() => navigate('/revise-conversation')}>
            {translations[selectedLanguage].ReviseThisConversation}
            <img style={{width:'22px', height:'25px', marginLeft:'auto'}} src="Icons/transcription-practice.svg" alt="" /></button> 
          <button className="report-button" onClick={() => navigate('/pronunciation-report')}>
            {translations[selectedLanguage].PronunciationReport}
            <img style={{width:'22px', height:'22px', marginLeft:'auto'}} src={"/Icons/practice.svg"} alt="" /> </button>
          <button className="report-button" onClick={() => navigate('/grammar-report')}>
            {translations[selectedLanguage].GrammarReport}
            <img style={{width:'20px', height:'20px', marginLeft:'auto'}} src={"/Icons/pencil.svg"} alt="" className="arrow-right" /> </button> 
        </div>
        <br/><br/><br/>

        <span 
          onClick={() => window.open('https://forms.gle/L1e9Vpj6AwML61hq8', '_blank')} 
          style={{
            textDecoration: 'underline',
            color: '#062E5F', // A blue color to mimic a link
            cursor: 'pointer',
            fontSize: 'clamp(18px, 4vw, 20px) !important'
          }}
        >
          {translations[selectedLanguage].GiveFeedback}
        </span>
        <br/><br/><br/>
      </div>
      <BottomMenuComponent steps={[]}/>   
    </div>
  );
};

export default CompletionPage;