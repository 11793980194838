import React, {useContext, useCallback, useState} from 'react';
import { useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUserAnswers } from '../Practice/UserAnswers';
import { translations } from '../Reusables/Utilities';
import { useLanguage } from '../Reusables/LanguageProvider';
import './TopicSelection.css'; 
import DataContext from '../Reusables/DataContext';
import BurgerMenuComponent from '../Reusables/BurgerMenuComponent';
import { usePlayAudio } from '../Reusables/PlayAudio';

const TopicSelection = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { level, updateLevel, updateLesson, updateLessonEn, updateLessonIndex, updateTopic, updateEnglishTopic} = useUserAnswers();
  const { setInstructionForConversation, setTopics, setLessons, topics, fetchTopics, lessons, fetchLessonGroups, sentencesForLesson, setSentencesForLesson, sentencesForStudy, setSentencesForStudy, setInstructionForLesson, setConversations} = useContext(DataContext);
  const { selectedLanguage } = useLanguage();
  const [clickTopic, setClickTopic] = useState(false);
  const [clickLesson, setClickLesson] = useState(false);
  const [clickConversations, setClickConversations] = useState(true);
  const [clickLessons, setClickLessons] = useState(true);
  const {  fromCompletionPage } = location.state || { fromCompletionPage: false};  
  const {  practiceConvo } = location.state || { practiceConvo: false};  
  const { stopAudio } = usePlayAudio(); 
    
  const goBack = useCallback(() => {        
    if (fromCompletionPage) { 
      navigate('/completion-page'); 
    } else {      
      navigate('/');      
    }
  });  
  useEffect(() => {
    const handleKeyDown = (event) => {      
      if (event.key === "ArrowLeft") {
        goBack(); 
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
  
    // Cleanup event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [goBack]);
  
  useEffect(() => {       
      if (topics.length === 0)   {
        fetchTopics(level, selectedLanguage);
      }
      if (lessons.length === 0){
        fetchLessonGroups(level, selectedLanguage);
      }   
      if(practiceConvo || fromCompletionPage){
        setClickLessons(false);
      }   
    return () => {
      stopAudio();  // when come from summary-page
    };          
  }, []);

  useEffect(() => {    
    if (clickLesson && sentencesForLesson.length === 0){
      setClickLesson(false);
      navigate('/lesson-instruction');
      // navigate('/lesson-instruction', { state: { fromTopicSelection: true } });
    }
  }, [clickLesson]);

  const handleClickLesson = (index, lessonName, lessonNameEn) => {   
    // setSentencesForStudy([]);
    // setConversations([]); 
    setInstructionForLesson('');
    setSentencesForLesson([]);
    updateLessonIndex(index);
    updateLesson(lessonName);
    updateLessonEn(lessonNameEn);
    updateTopic('');
    updateEnglishTopic('');
    setClickLesson(true);    
  };
  
  useEffect(() => {    
    if (clickTopic && sentencesForStudy.length === 0){
      setClickTopic(false);
      navigate('/conversation-instruction');
    }
  }, [clickTopic]);  
  
  const handleClickTopic = (topicName, topicNameEn) => {   
    setInstructionForConversation(''); 
    setSentencesForStudy([]);
    setConversations([]);
    // setSentencesForLesson([]);
    updateTopic(topicName);
    updateEnglishTopic(topicNameEn);
    updateLesson('');
    updateLessonEn('');
    setClickTopic(true);    
  };

  const handleClickLessons = () => {
    setClickLessons(!clickLessons);
  };

  const handleClickConversations = () => {
    setClickConversations(!clickConversations);
  };

  const nextLevel = () => {
    setTopics([]);    // to scroll mouse to the top of page in next level
    setLessons([]);
    // setSentencesForLesson([]);
    updateLevel(level + 1);  
    // setInstructionForLesson('');
    fetchTopics(level + 1, selectedLanguage);
    fetchLessonGroups(level + 1, selectedLanguage);
    setClickLessons(true);
  };

  return (    
    <div className="page-container">
      {level !== 0 ? (
         <div>
        <div className="top-row">
          <button onClick={() => goBack()} className="back-button"> <img src={`/Icons/arrow-left.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].back} />  </button>
          <h3>{translations[selectedLanguage].Level} {level} - {translations[selectedLanguage].chooseATopic}</h3>
          <div><BurgerMenuComponent /></div>  
        </div> 
    
        <div className="study-body">
          {level === 1 ? 
            lessons.map((lesson, index) => (
              <div key={index}  className="topics">
              <button className="topic-button" 
                onClick={() => handleClickLesson(index, lesson.lesson_name, lesson.lesson_name_en)} >
                <div style={{marginRight:"auto"}}>{lesson.lesson_name} </div>
                <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
              </button>    
              {index < lessons.length - 1 && <hr />}
              </div>            
            ))
          :
          <div>
            <button
              className="topic-options"
              onClick={() => handleClickLessons()}
            >
              <img  src={`/Icons/lessons.svg?${new Date().getTime()}`} alt="" />&nbsp; 
              <span>  {translations[selectedLanguage].Lessons}</span>
              <span style={{marginLeft:"auto"}}>{clickLessons ? ( 
                    <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                  ) : (
                    <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                  )}</span>
            </button>
    
            {clickLessons && lessons.map((lesson, index) => (
                <div key={index}  className="topics">
                <button className="topic-button" 
                  onClick={() => handleClickLesson(index, lesson.lesson_name, lesson.lesson_name_en)} >
                  <div style={{marginRight:"auto"}}>{lesson.lesson_name} </div>
                  <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
                </button>    
                {index < lessons.length - 1 && <hr />}
                </div>            
            ))}

            <button
              className="topic-options"
              onClick={() => handleClickConversations()}
            >
              &nbsp;<img  src={`/Icons/conversations.svg?${new Date().getTime()}`} alt="" />&nbsp; 
              <span>  {translations[selectedLanguage].Conversations}</span>
              <span style={{marginLeft:"auto"}}>{clickConversations ? ( 
                    <img src={`/Icons/arrow-up.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].collapse} />
                  ) : (
                    <img src={`/Icons/arrow-down.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].expand} />
                  )}</span>
            </button>
    
            {clickConversations && topics.map((topic, index) => (
                <div key={index}  className="topics">
                <button className="topic-button" 
                onClick={() => handleClickTopic(topic.topic_name, topic.topic_name_en)} >
                  <div style={{marginRight:"auto"}}>{topic.topic_name} </div>
                  <div style={{marginLeft:"auto"}}><img src={`/Icons/arrow-right.svg?${new Date().getTime()}`} alt={translations[selectedLanguage].next} /></div>
                </button>    
                {index < topics.length - 1 && <hr />}
                </div>            
            ))}   
          </div>  
          }               
        </div>  
        <br/>
{level < 4 &&
        <button style={{margin:'-15px auto 100px auto'}} className="practice-button" onClick={() => nextLevel()}><b>            
              {translations[selectedLanguage].NEXTLEVEL}
            </b></button> }
        </div>      
        ) : (
          <button className="topic-option" onClick={() => navigate('/')}>{translations[selectedLanguage].PleaseChooseALevel}</button>        
        )} 
    </div>
  );
}

export default TopicSelection;